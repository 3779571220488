export const BED_IPD_DASHBOARD_URL = '/bedIpd/ipVisit/dashboard'
export const USER_CREATATION = '/administration/userCreation'
export const Edit = 'edit'
export const Create = 'create'
export const doctor = '/administration/doctors'
export const partners = '/administration/referredOut'
export const radilogist = '/radilogist'
export const proRithmDashboard = '/proRithmDashboard'
export const pathologist = 'administration/pathologist'
export const labDevices = '/labDevices'
export const radiologyDevices = '/radiologyDevices'
