import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { startSxpProxy } from '../../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  IPD_PROJECT_ID,
  LABS_PROJECT_ID,
} from '../../../../utils/constants'
import { AdminIntent, menuValues } from '../../constants'
import { toast } from 'react-toastify'
import {
  messages,
  intent as intentConst,
} from '../../../administration/constants'
import { toastOptions } from '../../models'

export const DeleteConfirmationDialog = ({
  openDialog,
  closeDialog,
  data,
  name,
  setData,
  item,
}: any) => {
  let intent: string
  let state = {}
  let projectId: any

  if (item && item !== menuValues?.Sequence) {
    switch (item) {
      case menuValues?.Sample:
        ;(intent = AdminIntent?.DeleteSample),
          (state = { sampleId: data?.id }),
          (projectId = LABS_PROJECT_ID)
        break
      case menuValues?.Panel:
        ;(intent = AdminIntent?.DeletepanelById),
          (projectId = LABS_PROJECT_ID),
          (state = {
            panelId: data?.id,
            odooPanelId: data?.odoo_test_id,
          })
        break
      case menuValues?.Parameter:
        ;(intent = AdminIntent?.DeleteLabTest),
          (projectId = LABS_PROJECT_ID),
          (state = { lab_test_id: data?.id })
        break
      case menuValues?.LabDepartment:
        ;(intent = AdminIntent?.DeleteLabDepartment),
          (state = { departmentId: data?.id }),
          (projectId = LABS_PROJECT_ID)
        break
      case menuValues?.UnitType:
        ;(intent = AdminIntent?.DeleteUnitType),
          (projectId = LABS_PROJECT_ID),
          (state = { unit_type_id: data?.id })
        break
      case menuValues?.ResultType:
        ;(intent = AdminIntent?.DeleteResultType),
          (projectId = LABS_PROJECT_ID),
          (state = { result_type_id: data?.id })
        break
      case menuValues?.Method:
        ;(intent = AdminIntent?.DeleteMethodType),
          (projectId = LABS_PROJECT_ID),
          (state = { test_method_id: data?.id })
        break
      case menuValues?.ParameterRange:
        ;(intent = AdminIntent?.DeleteTestRange),
          (projectId = LABS_PROJECT_ID),
          (state = { test_result_limit_id: data?.id })
        break
      case menuValues?.Packages:
        ;(intent = AdminIntent?.DeletePackageById),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            odooPackageId: data?.package_id,
          })
        break
      case menuValues?.Ward:
        ;(intent = AdminIntent?.DeleteWard),
          (projectId = IPD_PROJECT_ID),
          (state = { ward_id: data?.id })
        break
      case menuValues?.Room:
        ;(intent = AdminIntent?.DeleteRoom),
          (projectId = IPD_PROJECT_ID),
          (state = { room_id: data?.id })
        break
      case menuValues?.Bed:
        ;(intent = AdminIntent?.DeleteBed),
          (projectId = LABS_PROJECT_ID),
          (state = { bed_id: data?.id })
        break
      case menuValues?.Doctor:
        ;(intent = AdminIntent?.DeleteDoctor),
          (projectId = ADMIN_PROJECT_ID),
          (state = { id: data?.id })
        break
      case menuValues?.Location:
        ;(intent = intentConst?.deleteLocation),
          (projectId = ADMIN_PROJECT_ID),
          (state = { locId: data?.resource?.id })
        break
      case menuValues?.Pathologist:
        ;(intent = AdminIntent?.DeletePathologist),
          (projectId = ADMIN_PROJECT_ID),
          (state = { id: data?.id })
        break
      case menuValues?.Partner:
        ;(intent = intentConst?.deletePartner),
          (projectId = ADMIN_PROJECT_ID),
          (state = { partner_id: data?.id, odooId: data?.odoo_partner_id })
        break
      default:
        intent = ''
    }
  }

  const handleConfirmDelete = () => {
    if (openDialog) {
      startSxpProxy(projectId, intent, state)
        ?.then(() => {
          toast.success(messages?.Deletedsuccessfully)
          if (data?.id) {
            setData((prevData: any) =>
              prevData?.filter((prev: any) => prev?.id !== data?.id)
            )
          } else {
            setData((prevData: any) =>
              prevData?.filter(
                (prev: any) => prev?.resource?.id !== data?.resource?.id
              )
            )
          }
        })
        ?.catch((err) => {
          toast.error(err?.response?.data?.message, toastOptions)
        })
    }
    closeDialog()
  }

  return (
    <Dialog open={openDialog}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {'Are you sure you want to delete the '}
          {item === 'beds' && 'bed number '}
          <strong>{name}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color='secondary' autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
