import {
  Button,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import MenBodySvgComponent from './MenBodySvgComponent'
import MenBackBodySvgComponent from './MenBackBodySvgComponent'
import './Menbody.css'
import './Variables.css'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CloseIcon from '@mui/icons-material/Close'
import {
  displayInvasiveLine,
  invasiveLinesGet,
  invasiveLinesPost,
} from './Constants'
import { useParams } from 'react-router-dom'
import { startSxpProxy } from '../../utils/api'
import { IPD_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import { toast } from 'react-toastify'

const styles = () => ({
  invasiveLinesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
  },
  invasiveLinesBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    paddingTop: 30,
  },
  invasiveLinesPopoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 8,
  },
  invasiveLinesTableContainer: {
    paddingTop: 30,
    width: '40%',
  },
  invasiveLinesTable: {
    border: '1px solid #e6e6e6',
    width: '100%',
  },
  invasiveLinesTableHead: {
    backgroundColor: '#4682b4',
    '& th': {
      color: '#ffffff',
      padding: '4px',
      fontSize: '12px',
    },
  },
  invasiveLinesTableCell: {
    border: '1px solid #e6e6e6',
    padding: '4px',
    fontSize: '12px',
  },
  widthInvasiveCell: {
    width: '15%',
  },
  widthValueCell: {
    width: '15%',
  },
  widthActionsCell: {
    width: '3%',
  },
  invasiveLinesPopoverBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'capitalize',
    width: 100,
    alignSelf: 'center',
  },
  invasiveLinesPopoverHeading: {
    color: '#183f7c',
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: 600,
  },
  invasiveLinesPopoverTextField: {
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
      minWidth: '240px',
    },
  },
  invasiveLinesSubmitContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
  },
  invasiveLinesSubmitBtn: {
    backgroundColor: '#4682b4',
    textTransform: 'capitalize',
    width: 100,
  },
  invasiveLinesDisable: {
    pointerEvents: 'none',
  },
})

interface InvasiveLinesData {
  [key: string]: any
}

const InvasiveLines = () => {
  const { id } = useParams()
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const [currentInvasiveLine, setCurrentInvasiveLine] = React.useState('')
  const [invasiveLinesData, setInvasiveLinesData] =
    React.useState<InvasiveLinesData>({})
  const [selectedDot, setSelectedDot] = React.useState('')
  const [visitDetails, setVisitDetails] = React.useState<any>({})
  const [submit, setSubmit] = React.useState(true)

  const invasiveLinesFront = [
    { cx: 83, cy: 8, name: 'evd' },
    { cx: 83, cy: 23, name: 'ryles' },
    { cx: 83, cy: 30, name: 'etTube' },
    { cx: 76, cy: 44, name: 'cvcHdRight' },
    { cx: 90, cy: 44, name: 'cvcHdLeft' },
    { cx: 83, cy: 54, name: 'tracheostomyTube' },
    { cx: 107, cy: 86, name: 'icdLeft' },
    { cx: 59, cy: 86, name: 'icdRight' },
    { cx: 40, cy: 108, name: 'peripheralLinesRightHand' },
    { cx: 127, cy: 108, name: 'peripheralLinesLeftHand' },
    { cx: 90, cy: 120, name: 'peg' },
    { cx: 20, cy: 148, name: 'arterialLinesRight' },
    { cx: 148, cy: 146, name: 'arterialLinesLeft' },
    { cx: 67, cy: 155, name: 'femoralRight' },
    { cx: 98, cy: 155, name: 'femoralLeft' },
    { cx: 83.5, cy: 164, name: 'foleysCatheter' },
    { cx: 53, cy: 300, name: 'peripheralLinesRightLeg' },
    { cx: 120, cy: 300, name: 'peripheralLinesLeftLeg' },
  ]

  const invasiveLinesBack = [{ cx: 86, cy: 175, name: 'flatusTube' }]

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const idPopover = open ? 'simple-popover' : undefined

  const handleClickDots = (e: any) => {
    setSelectedDot(e.target.id)
    setCurrentInvasiveLine(invasiveLinesData?.[e.target.id])
    handleClick(e)
  }

  const handleInvasiveLineSubmit = () => {
    setInvasiveLinesData((prevState) => ({
      ...prevState,
      [selectedDot]: currentInvasiveLine,
    }))
    handleClose()
  }

  const handleCurrentInvasiveLine = (e: any) => {
    setCurrentInvasiveLine(e.target.value)
    setSubmit(false)
  }

  const removeInvasiveLine = (invasiveLine: string) => {
    const newObject = { ...invasiveLinesData }
    delete newObject[invasiveLine]
    setInvasiveLinesData(newObject)
  }

  const getInvasiveLines = async () => {
    const intent = invasiveLinesGet
    const state = { encounterId: id }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          setInvasiveLinesData(data?.data?.data)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }

  const handleSubmitInvasiveLines = useCallback(async () => {
    const intent = invasiveLinesPost
    const state = { ...invasiveLinesData, encounterId: id }

    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          setInvasiveLinesData(data?.data?.data)
          toast.success(data?.data?.message)
          setSubmit(true)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
        toast.error(err?.response?.data?.message)
      })
  }, [invasiveLinesData, id])

  const getVisitDetails = () => {
    const state = { encounterId: id }
    startSxpProxy(OPD_PROJECT_ID, 'getVisitApi', state)
      .then((data) => {
        setVisitDetails(data?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getInvasiveLines()
    getVisitDetails()
    setSubmit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div
      className={`${classes?.invasiveLinesContainer} ${
        visitDetails?.statusHistory?.[0]?.status === 'finished' ||
        visitDetails?.statusHistory?.[0]?.status === 'cancelled'
          ? classes?.invasiveLinesDisable
          : ''
      }`}
    >
      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={true}
      >
        <div className={classes?.invasiveLinesPopoverContainer}>
          <div className={classes?.invasiveLinesPopoverHeading}>
            {displayInvasiveLine(selectedDot)}
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 2,
                top: 2,
                color: (theme) => theme.palette.grey[500],
              }}
              size='small'
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
          <TextField
            className={classes?.invasiveLinesPopoverTextField}
            name={selectedDot}
            value={currentInvasiveLine}
            onChange={handleCurrentInvasiveLine}
            size='small'
            multiline
            rows={2}
          />
          <Button
            variant='contained'
            onClick={handleInvasiveLineSubmit}
            size='small'
            className={classes?.invasiveLinesPopoverBtn}
          >
            Save
          </Button>
        </div>
      </Popover>
      <div className={classes?.invasiveLinesBodyContainer}>
        <MenBodySvgComponent
          handlePieceClick={handleClickDots}
          invasiveLines={invasiveLinesFront}
        />
        <MenBackBodySvgComponent
          handlePieceClick={handleClickDots}
          invasiveLines={invasiveLinesBack}
        />
      </div>
      <div className={classes?.invasiveLinesTableContainer}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table className={classes?.invasiveLinesTable}>
            <TableHead className={classes?.invasiveLinesTableHead}>
              <TableRow>
                <TableCell
                  className={`${classes?.invasiveLinesTableCell} ${classes?.widthInvasiveCell}`}
                >
                  Invasive Line
                </TableCell>
                <TableCell
                  className={`${classes?.invasiveLinesTableCell} ${classes?.widthValueCell}`}
                >
                  Value
                </TableCell>
                <TableCell
                  className={`${classes?.invasiveLinesTableCell} ${classes?.widthActionsCell}`}
                  align='center'
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(invasiveLinesData ?? {})
                .filter(
                  ([key]) =>
                    key !== 'startTime' &&
                    key !== 'observationId' &&
                    key !== 'patientId' &&
                    key !== 'encounterId'
                )
                ?.map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell className={classes?.invasiveLinesTableCell}>
                      {displayInvasiveLine(key)}
                    </TableCell>
                    <TableCell className={classes?.invasiveLinesTableCell}>
                      {value
                        ?.split(/[\n, ]+/)
                        ?.map((line: string, index: number) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </TableCell>
                    <TableCell
                      className={classes?.invasiveLinesTableCell}
                      align='center'
                    >
                      <IconButton
                        size='small'
                        onClick={() => removeInvasiveLine(key)}
                      >
                        <DeleteOutlineOutlinedIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes?.invasiveLinesSubmitContainer}>
          {visitDetails?.statusHistory?.[0]?.status === 'finished' ||
          visitDetails?.statusHistory?.[0]?.status === 'cancelled' ||
          Object.keys(invasiveLinesData).filter(
            (key) =>
              ![
                'startTime',
                'observationId',
                'patientId',
                'encounterId',
              ].includes(key)
          ).length <= 0 ? null : (
            <Button
              variant='contained'
              size='small'
              className={classes?.invasiveLinesSubmitBtn}
              onClick={handleSubmitInvasiveLines}
              disabled={submit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvasiveLines
