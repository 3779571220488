export const PackageTableHeading = {
  ThopId: 'Thop Id',
  OdooId: 'Odoo Id',
  PackageName: 'Package Name',
  TestName: 'TestName',
  TestCount: 'Test Count',
  Price: 'Price',
  StartEnd: 'Start-End Date',
  Created: 'Created By',
  Updated: 'Updated By',
  Status: 'Status',
  Actions: 'Actions',
}

export const panelTableHeadings = {
  ThopId: 'Thop Id',
  OdooId: 'Odoo Id',
  ShortForm: 'Short Form',
  TestName: 'Test Name',
  Department: 'Department',
  Sample: 'Sample',
  ParameterName: 'Parameter Name',
  ParaCount: 'Para Count',
  HandledAt: 'Handled At',
  Price: 'Price',
  TAT: 'TAT',
  Interpretation: 'Interpretation',
  Status: 'Status',
  Actions: 'Actions',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
}

export const PanelsIntent = {
  getAllDepartmentsApi: 'getAllDepartmentsApi',
  getAllSamplesApi: 'getAllSamplesApi',
  updatePanelById: 'updatePanelById',
  createUpdatePanel: 'createUpdatePanel',
}
