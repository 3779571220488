import React, { useEffect, useState } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { useTheme } from '@emotion/react'
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { startSxpProxy } from '../../utils/api'
import { IPD_PROJECT_ID } from '../../utils/constants'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import HotelIcon from '@mui/icons-material/Hotel'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import ClearIcon from '@mui/icons-material/Clear'
import InputAdornment from '@mui/material/InputAdornment'
import { bedLayout } from './constants'

interface Bed {
  bed_description: string
  bed_number: string
  status: 'OCCUPIED' | 'AVAILABLE'
}

interface Room {
  [roomName: string]: Bed[]
}

interface Department {
  [departmentName: string]: Room
}

interface Hospital {
  [hospitalName: string]: Department
}

const styles = () => ({
  mainDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 40,
    marginBottom: '20px',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },
  bedFilteringContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '95%',
  },
  bedHeading: {
    fontSize: '17px',
    fontWeight: 600,
    color: '#183f7c',
  },
  bedHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '5%',
    width: '20%',
  },
  bedFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '2%',
    rowGap: 15,
    width: '80%',
  },
  filterField: {
    minWidth: '12rem',
  },
  bedsMapContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '95%',
  },
  locationText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#183f7c',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    width: '50%',
  },

  wardText: {
    fontSize: '15px',
    fontWeight: 500,
  },
  wardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },

  roomText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  roomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 5,
  },
  bedsLayoutContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '4%',
    rowGap: '2rem',
    flexWrap: 'wrap',
    width: '80%',
  },
  bedIconOccupied: {
    color: 'red',
    fontSize: '70px',
  },
  bedIconAvaliable: {
    color: 'green',
    fontSize: '70px',
  },
  bedNumber: {
    fontSize: '10px',
  },
  collapseView: {
    display: 'none',
  },
  clearIcon: {
    fontSize: '15px',
  },
  clearIconContainer: {
    marginRight: 15,
  },
})

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

/* eslint-disable @typescript-eslint/no-unused-vars */

const RenderLocation = ({ data }: { data: Hospital }) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [openLocations, setOpenLocations] = useState<{
    [key: string]: boolean
  }>({})

  const countBedsByStatus = (
    data: Department,
    status: 'AVAILABLE' | 'OCCUPIED'
  ): number => {
    let count = 0

    Object.keys(data)?.forEach((ward) => {
      const rooms = data[ward]
      Object.keys(rooms)?.forEach((room) => {
        const beds = rooms?.[room]
        beds?.forEach((bed) => {
          if (bed?.status === status) {
            count++
          }
        })
      })
    })

    return count
  }

  useEffect(() => {
    const initialOpenLocations = Object.keys(data)?.reduce(
      (acc, location, index) => {
        acc[location] = index === 0
        return acc
      },
      {} as { [key: string]: boolean }
    )
    setOpenLocations(initialOpenLocations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleToggle = (location: string) => {
    setOpenLocations((prevState) => ({
      ...prevState,
      [location]: !prevState[location],
    }))
  }
  return (
    <>
      {Object.entries(data)?.map(([location, wards]) => (
        <Box
          key={location}
          className={classes?.flexColumn}
          width={'100%'}
          component={Paper}
          borderRadius={3}
          p={1}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'95%'}
          >
            <div className={classes?.locationContainer}>
              <div className={classes?.locationText}>{location}</div>
              <IconButton size='small' onClick={() => handleToggle(location)}>
                {openLocations?.[location] ? (
                  <ExpandMoreIcon fontSize='small' />
                ) : (
                  <ExpandLessIcon fontSize='small' />
                )}
              </IconButton>
            </div>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'end'}
              width='50%'
            >
              <Box display='flex' alignItems='center' gap={3} flexWrap={'wrap'}>
                <Box display='flex' alignItems='center'>
                  <Box
                    width={20}
                    height={20}
                    borderRadius={50}
                    bgcolor='green'
                    marginRight={1}
                  />
                  <Typography fontSize={'11px'}>
                    {countBedsByStatus(data?.[location], 'AVAILABLE')}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Box
                    width={20}
                    height={20}
                    borderRadius={50}
                    bgcolor='red'
                    marginRight={1}
                  />
                  <Typography fontSize={'11px'}>
                    {' '}
                    {countBedsByStatus(data?.[location], 'OCCUPIED')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {openLocations?.[location] && <RenderWard data={wards} />}
        </Box>
      ))}
    </>
  )
}

const RenderWard = ({ data }: { data: Department }) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [openWards, setOpenWards] = useState<{
    [key: string]: boolean
  }>({})

  useEffect(() => {
    const initialOpenWards = Object.keys(data).reduce((acc, ward) => {
      acc[ward] = true
      return acc
    }, {} as { [key: string]: boolean })

    setOpenWards(initialOpenWards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = (ward: string) => {
    setOpenWards((prevState) => ({
      ...prevState,
      [ward]: !prevState[ward],
    }))
  }
  return (
    <>
      {Object.entries(data)?.map(
        ([ward, rooms]) =>
          rooms &&
          Object.keys(rooms)?.length > 0 &&
          Object.values(rooms)?.some((beds) => beds && beds?.length > 0) && (
            <Box key={ward} className={classes?.flexColumn} width={'90%'}>
              <div className={classes?.wardContainer}>
                <div className={classes?.wardText}>{ward}-Ward</div>
                <IconButton size='small' onClick={() => handleToggle(ward)}>
                  {openWards?.[ward] ? (
                    <ExpandMoreIcon fontSize='small' />
                  ) : (
                    <ExpandLessIcon fontSize='small' />
                  )}
                </IconButton>
              </div>
              {openWards?.[ward] && <RenderRoom data={rooms} />}
            </Box>
          )
      )}
    </>
  )
}

const RenderRoom = ({ data }: { data: Room }) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [openRooms, setOpenRooms] = useState<{
    [key: string]: boolean
  }>({})

  useEffect(() => {
    const initialOpenRooms = Object.keys(data).reduce((acc, room) => {
      acc[room] = true
      return acc
    }, {} as { [key: string]: boolean })

    setOpenRooms(initialOpenRooms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = (room: string) => {
    setOpenRooms((prevState) => ({
      ...prevState,
      [room]: !prevState[room],
    }))
  }
  return (
    <>
      {Object.entries(data)?.map(
        ([room, beds]) =>
          beds &&
          beds?.length > 0 && (
            <Box key={room} className={classes?.flexColumn} width={'85%'}>
              <div className={classes?.roomContainer}>
                <div className={classes?.roomText}>{room}-Room</div>
                <IconButton size='small' onClick={() => handleToggle(room)}>
                  {openRooms?.[room] ? (
                    <ExpandMoreIcon fontSize='small' />
                  ) : (
                    <ExpandLessIcon fontSize='small' />
                  )}
                </IconButton>
              </div>
              {openRooms?.[room] && <RenderBed data={beds} />}
            </Box>
          )
      )}
    </>
  )
}

const RenderBed = ({ data }: { data: Bed[] }) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  return (
    <div className={classes?.bedsLayoutContainer}>
      {data?.map((item, index) => (
        <Box
          key={index}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={0.5}
        >
          <HotelIcon
            className={
              item?.status === 'OCCUPIED'
                ? classes?.bedIconOccupied
                : classes?.bedIconAvaliable
            }
          />
          <div className={classes?.bedNumber}>{item?.bed_number}</div>
        </Box>
      ))}
    </div>
  )
}

const BedLayout = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const [allLocationBeds, setAllLocationBeds] = useState<Hospital>({})
  const [allLocationBedsClone, setAllLocationBedsClone] = useState<Hospital>({})
  const [allLocations, setAllLocations] = useState(false)

  const [locations, setLocations] = useState<string[]>([])
  const [wards, setWards] = useState<string[]>([])
  const [rooms, setRooms] = useState<string[]>([])

  const [location, setLocation] = useState('')
  const [ward, setWard] = useState('')
  const [room, setRoom] = useState('')

  const getAllLocationBeds = () => {
    const intent = 'getBedDetailsForAllLocations'
    const state = allLocations ? { status: 'AVAILABLE' } : {}
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        setAllLocationBeds(data?.data?.data)
        setAllLocationBedsClone(data?.data?.data)
        const formattedLocations = Object.entries(data.data?.data || {})?.map(
          ([location, wards]) => location
        )
        setLocations(formattedLocations)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const filterData = (
    data: Hospital,
    location?: string,
    ward?: string,
    room?: string
  ) => {
    const filteredData: any = {}

    if (!location) {
      return setAllLocationBeds(data)
    }

    if (location) {
      const locationData = data[location]
      if (locationData) {
        filteredData[location] = {}

        if (ward) {
          const wardData = locationData[ward]
          if (wardData) {
            filteredData[location][ward] = {}

            if (room) {
              filteredData[location][ward][room] = wardData[room] || []
            } else {
              filteredData[location][ward] = wardData
            }
          } else {
            filteredData[location] = locationData
          }
        } else {
          filteredData[location] = locationData
        }
      }
    }

    setAllLocationBeds(filteredData)
  }

  useEffect(() => {
    getAllLocationBeds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations])

  useEffect(() => {
    setWard('')
    setRoom('')
    const formattedWards = Object.entries(
      allLocationBedsClone?.[location] || {}
    )
      .filter(
        ([ward, rooms]) =>
          rooms &&
          Object.keys(rooms)?.length > 0 &&
          Object.values(rooms)?.some((beds) => beds && beds?.length > 0)
      )
      .map(([ward]) => ward)

    setWards(formattedWards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    const formattedRooms = Object.entries(
      allLocationBedsClone?.[location]?.[ward] || {}
    )
      ?.filter(([room, beds]) => beds && beds?.length > 0)
      ?.map(([room, beds]) => room)
    setRooms(formattedRooms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ward])

  useEffect(() => {
    filterData(allLocationBedsClone, location, ward, room)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, ward, room])

  return (
    <div className={classes?.mainDiv}>
      <div className={classes?.bedFilteringContainer}>
        <div className={classes?.bedHeadingContainer}>
          <div className={classes?.bedHeading}>{bedLayout}</div>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography fontSize={'11px'}>All</Typography>
            <AntSwitch
              value={allLocations}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={(e) => setAllLocations(e.target.checked)}
            />
            <Typography fontSize={'11px'}>Avaliable</Typography>
          </Stack>
        </div>

        <div className={classes?.bedFilterContainer}>
          <Box display='flex' alignItems='center' gap={3} flexWrap={'wrap'}>
            <Box display='flex' alignItems='center'>
              <Box
                width={20}
                height={20}
                borderRadius={50}
                bgcolor='green'
                marginRight={1}
              />
              <Typography>Available</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Box
                width={20}
                height={20}
                borderRadius={50}
                bgcolor='red'
                marginRight={1}
              />
              <Typography>Occupied</Typography>
            </Box>
          </Box>
          <Select
            displayEmpty
            size='small'
            className={classes?.filterField}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            renderValue={(selected) => {
              if (selected === '') {
                return <em>Location</em>
              }
              return selected
            }}
            MenuProps={{
              disableScrollLock: true,
            }}
            endAdornment={
              location && (
                <InputAdornment
                  position='end'
                  className={classes?.clearIconContainer}
                >
                  <IconButton
                    onClick={() => setLocation('')}
                    edge='end'
                    size='small'
                    aria-label='clear selection'
                  >
                    <ClearIcon className={classes?.clearIcon} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            <MenuItem disabled value=''>
              Location
            </MenuItem>
            {locations?.map((item: string, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>

          <Select
            displayEmpty
            size='small'
            className={classes?.filterField}
            value={ward}
            onChange={(e) => setWard(e.target.value)}
            renderValue={(selected) => {
              if (selected === '') {
                return <em>Ward</em>
              }
              return selected
            }}
            disabled={!location}
            MenuProps={{
              disableScrollLock: true,
            }}
            endAdornment={
              ward && (
                <InputAdornment
                  position='end'
                  className={classes?.clearIconContainer}
                >
                  <IconButton
                    onClick={() => setWard('')}
                    edge='end'
                    size='small'
                    aria-label='clear selection'
                  >
                    <ClearIcon className={classes?.clearIcon} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            <MenuItem disabled value=''>
              Ward
            </MenuItem>
            {wards?.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>

          <Select
            displayEmpty
            size='small'
            className={classes?.filterField}
            value={room}
            onChange={(e) => setRoom(e.target.value)}
            renderValue={(selected) => {
              if (selected === '') {
                return <em>Room</em>
              }
              return selected
            }}
            disabled={!ward}
            MenuProps={{
              disableScrollLock: true,
            }}
            endAdornment={
              room && (
                <InputAdornment
                  position='end'
                  className={classes?.clearIconContainer}
                >
                  <IconButton
                    onClick={() => setRoom('')}
                    edge='end'
                    size='small'
                    aria-label='clear selection'
                  >
                    <ClearIcon className={classes?.clearIcon} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            <MenuItem disabled value=''>
              Room
            </MenuItem>
            {rooms?.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </div>
      <div className={classes?.bedsMapContainer}>
        <RenderLocation data={allLocationBeds} />
      </div>
    </div>
  )
}

export default BedLayout
