import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
  selectedLmsStatus,
} from '../../lmsSlice'
import { getBedResults } from '../../utils'
import LinkButton from './LinkButton'
import { bedType, create, menuValues, update } from '../../constants'
import { allocation } from '../../../bedManagement/modals'
import {
  emptyString,
  activeText,
  inactiveText,
} from '../../../Radiology/constants'
import ProvenanceView from '../../../../components/ProvenanceView'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const Beds = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [bedData, setBedData] = useState<any>({})
  const [tableData, setTableData] = useState<any>(data)
  let filtered = getBedResults(tableData, searchParam, locationSearch)
  filtered = filtered?.filter((d: any) => {
    const bedStatus = d?.active ? activeText : inactiveText
    return status === bedStatus
  })
  filtered?.sort?.((a: { bed_number: string }, b: { bed_number: string }) =>
    a.bed_number?.toLowerCase() < b.bed_number?.toLowerCase() ? -1 : 1
  )
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setBedData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setBedData(null)
  }

  useEffect(() => {
    setTableData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={sampleTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead className='sticky'>
          <tr>
            <th className='table-w-5'>{bedType?.seqNo}</th>
            <th>{bedType?.bedName}</th>
            <th>{bedType?.roomName}</th>
            <th>{bedType?.bedCategory}</th>
            <th>{bedType?.floor}</th>
            <th>{bedType?.location}</th>
            <th className='table-w-8'>{bedType?.createdBy}</th>
            <th className='table-w-8'>{bedType?.updatedBy}</th>
            <th className='table-w-8'>{bedType?.actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any) => (
              <tr key={d.id}>
                <td>{d.sequence_number ?? '-'}</td>
                <td>
                  <LinkButton id={d.id} name={d.bed_number} onClick={onEdit} />
                </td>
                <td>{d?.room_detail?.room_name}</td>
                <td>
                  {d?.bed_category_id === 3
                    ? allocation.critical
                    : allocation.nonCritical}
                </td>
                <td>{d?.room_detail.floor_number ?? emptyString}</td>
                <td>{d?.location_id}</td>
                <td>
                  <ProvenanceView
                    mode={create}
                    date={d?.date_created ?? ''}
                    name={d?.created_by ?? '-'}
                  />
                </td>
                <td>
                  <ProvenanceView
                    mode={update}
                    date={d?.date_changed ?? ''}
                    name={d?.updated_by ?? '-'}
                  />
                </td>
                <td>
                  <div className='printButtons'>
                    {d?.active ? (
                      <IconButton
                        title='Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Bed)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <ArchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <IconButton
                        title='Un-Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Bed)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <UnarchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton
                      title='Delete'
                      size='small'
                      onClick={() => handleDeleteDialogOpen(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {bedType.noBeds}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={bedData}
          name={bedData?.bed_number}
          setData={setTableData}
          item={menuValues?.Bed}
        />
      )}
    </div>
  )
}

export default Beds
