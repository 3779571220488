import KeycloakService from '../../../utils/keycloakService'
import { APPOINTMENTS_WRITE } from '../../../utils/roles'
import { isoDateFormat } from '../../../utils/dateUtils'
import { useState } from 'react'

type Props = {
  initialStatus: string
  disabled: boolean
  handleChange: (status: string) => void
  appointmentDate: string
}

const statuses: any = {
  proposed: [
    { name: 'Proposed', value: 'proposed' },
    { name: 'Cancel', value: 'cancelled' },
  ],
  booked: [
    { name: 'Booked', value: 'booked' },
    { name: 'Arrived', value: 'arrived' },
    { name: 'Cancel', value: 'cancelled' },
  ],
  arrived: [
    { name: 'Arrived', value: 'arrived' },
    { name: 'Check In', value: 'checked-in' },
    { name: 'Cancel', value: 'cancelled' },
  ],
  'checked-in': [
    { name: 'Checked In', value: 'checked-in' },
    { name: 'Complete', value: 'fulfilled' },
  ],
  fulfilled: [{ name: 'Completed', value: 'fulfilled' }],
  cancelled: [{ name: 'Cancelled', value: 'cancelled' }],
}

const StatusChange = ({
  initialStatus,
  disabled,
  handleChange,
  appointmentDate,
}: Props) => {
  const [key, setKey] = useState(Date.now())

  const handleSelect = (val: string) => {
    const today = isoDateFormat(new Date())
    const appDate = isoDateFormat(new Date(appointmentDate))
    if (today < appDate && val !== 'cancelled') {
      alert('Cannot update status of future appointments...')
      setKey(Date.now())
      return
    }
    handleChange(val)
    setKey(Date.now())
  }

  return (
    <select
      key={key}
      disabled={disabled || !KeycloakService.hasRole([APPOINTMENTS_WRITE])}
      className={
        !disabled && !KeycloakService.hasRole([APPOINTMENTS_WRITE])
          ? 'auth-disabled'
          : 'medication-input w75'
      }
      onChange={(e: any) => handleSelect(e.target.value)}
    >
      {statuses[initialStatus]?.map((op: any) => (
        <option key={op?.value} value={op?.value}>
          {op?.name}
        </option>
      ))}
    </select>
  )
}

export default StatusChange
