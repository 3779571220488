import { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
  selectedLmsStatus,
} from '../../lmsSlice'
import { getWardResults } from '../../utils'
import LinkButton from './LinkButton'
import { wardType, create, update, menuValues } from '../../constants'
import { allocation } from '../../../bedManagement/modals'
import ProvenanceView from '../../../../components/ProvenanceView'
import { IconButton } from '@mui/material'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import KeycloakService from '../../../../utils/keycloakService'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { activeText, inactiveText } from '../../../Radiology/constants'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const Wards = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [wardData, setWardData] = useState<any>({})
  const status = useAppSelector(selectedLmsStatus)
  let filtered = getWardResults(tableData, searchParam, locationSearch)
  filtered = filtered?.filter((d: any) => {
    const wardStatus = d?.active ? activeText : inactiveText
    return status === wardStatus
  })
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setWardData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setWardData(null)
  }

  useEffect(() => {
    setTableData(data)
  }, [data])

  return (
    <>
      <div
        className='data-table table-fixed admin-table-table-container'
        ref={sampleTableContainerRef}
        style={{ overflowY: 'auto' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead className='sticky'>
            <tr>
              <th className='table-w-5'>{wardType?.id}</th>
              <th className='table-w-8'>{wardType?.ward}</th>
              <th className='table-w-8'>{wardType?.floor}</th>
              <th className='table-w-8'>{wardType?.location}</th>
              <th className='table-w-8'>{wardType?.maximumBeds}</th>
              <th className='table-w-8'>{wardType?.createdBy}</th>
              <th className='table-w-8'>{wardType?.updatedBy}</th>
              <th className='table-w-5'>{wardType?.actions}</th>
            </tr>
          </thead>
          <tbody>
            {filtered?.length ? (
              filtered.map((d: any) => (
                <tr key={d.id}>
                  <td>{d.id}</td>
                  <td>
                    <LinkButton id={d.id} name={d.ward_name} onClick={onEdit} />
                  </td>
                  <td>{d.floor_number ?? allocation.hyphen}</td>
                  <td>{d?.location_id ?? allocation.hyphen}</td>
                  <td>{d?.maximum_beds}</td>
                  <td>
                    <ProvenanceView
                      mode={create}
                      date={d?.date_created ?? ''}
                      name={d?.created_by ?? '-'}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      mode={update}
                      date={d?.date_changed ?? ''}
                      name={d?.updated_by ?? '-'}
                    />
                  </td>
                  <td>
                    <div className='set-action-icons'>
                      {d?.active ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Ward)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(d, setTableData, menuValues?.Ward)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        size='small'
                        onClick={() => handleDeleteDialogOpen(d)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined fontSize='small' />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className='text-center'>
                  {wardType.noWards}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={wardData}
          name={wardData?.ward_name}
          setData={setTableData}
          item={menuValues?.Ward}
        />
      )}
    </>
  )
}

export default Wards
