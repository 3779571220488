import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import LinkButton from './LinkButton'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { AdminTableProps } from '../../models'
import ProvenanceView from '../../../../components/ProvenanceView'
import {
  create,
  update,
  methodTableHeadings,
  menuValues,
} from '../../constants'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { activeText } from '../../../Radiology/constants'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const MethodTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [methodTypeData, setMethodTypeData] = useState<any>({})
  const filtered = tableData?.filter((d: any) => {
    const name = d.method_name?.toLowerCase()?.includes(searchParam)
    const methodStatus = d?.status === status
    return name && methodStatus
  })
  filtered?.sort?.((a: any, b: any) =>
    a.method_name?.toLowerCase() < b.method_name?.toLowerCase() ? -1 : 1
  )

  const methodTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = methodTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    setTableData(data)
  }, [data])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setMethodTypeData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setMethodTypeData(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={methodTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>{methodTableHeadings?.id}</th>
            <th>{methodTableHeadings?.method}</th>
            <th>{methodTableHeadings?.description}</th>
            <th className='table-w-10'>{methodTableHeadings?.createdBy}</th>
            <th className='table-w-10'>{methodTableHeadings?.updatedBy}</th>
            <th className='table-w-8'>{methodTableHeadings?.actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d?.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton
                    id={d.id}
                    name={d?.method_name}
                    onClick={onEdit}
                  />
                </td>
                <td>{d?.method_description}</td>
                <td>
                  <ProvenanceView
                    mode={create}
                    date={d?.created_date ?? ''}
                    name={d?.created_by ?? ''}
                  />
                </td>
                <td>
                  <ProvenanceView
                    mode={update}
                    date={d?.updated_date ?? ''}
                    name={d?.updated_by ?? ''}
                  />
                </td>
                <td>
                  <div className='set-action-icons'>
                    {d?.status === activeText ? (
                      <IconButton
                        title='Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Method)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <ArchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <IconButton
                        title='un-Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Method)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <UnarchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton
                      title='Delete'
                      size='small'
                      onClick={() => handleDeleteDialogOpen(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                No Method Types Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={methodTypeData}
          name={methodTypeData?.method_name}
          setData={setTableData}
          item={menuValues?.Method}
        />
      )}
    </div>
  )
}

export default MethodTable
