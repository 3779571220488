import { useEffect, useRef, useState, ChangeEvent, useCallback } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { Practitioner, PractitionerRole, Provenance } from 'fhir/r4'
import { FormDoctor } from './DoctorsForm'
import { ProvenanceData } from '../../patients/patientsSlice'
import { getProvenanceData } from '../../patients/utils'
import { emptyString } from '../../Radiology/constants'
import { doctors } from '../../patientRegstration/constants'
import {
  create,
  formValues,
  forms,
  intent,
  placeHolder,
  tableText,
  update,
} from '../constants'
import ProvenanceView from '../../../components/ProvenanceView'
import CreateButton from '../../../components/CreateButton'
import EditButton from '../../../components/EditButton'
import '../admin.scss'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import KeycloakService from '../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../utils/roles'
import { IconButton } from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { DeleteConfirmationDialog } from '../../lms/components/admin/DeleteConfirmationDialog'
import { Archive } from '../../lms/components/admin/Archive'
import { menuValues } from '../../lms/constants'
import { Create, Edit } from '../../bedManagement/endpoints/EndPoints'

export const Doctors = () => {
  const [doctorsData, setDoctorsData] = useState<FormDoctor[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState('active')
  const navigate = useNavigate()
  const ids = doctorsData.map((p) => p.id).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [doctor, setDoctor] = useState<any>({})

  const fetchProvenancesOfDoctors = useCallback(async () => {
    if (ids) {
      startSxpProxy(ADMIN_PROJECT_ID, intent?.fetchProvByDoctors, {
        pracIds: ids,
      })?.then((data) => {
        const entries =
          data.data?.entry?.map(
            (dde: { resource: Provenance | Practitioner }) => dde?.resource
          ) ?? []
        const obj = getProvenanceData(entries)
        setProvenances(obj)
      })
    }
  }, [ids])

  useEffect(() => {
    fetchProvenancesOfDoctors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, doctorsData])
  const formatDoctors = (
    roles: { resource: PractitionerRole }[],
    id?: string
  ) => {
    if (!id) {
      return 'NA'
    }

    const resType = roles.find(
      (e) => e.resource?.practitioner?.reference?.split?.('/')?.[1] === id
    )
    const code =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
    const display =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
    return `${display}::${code}`
  }
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const fetchDoctorsAsync = useCallback(async () => {
    startSxpProxy(ADMIN_PROJECT_ID, intent?.getDoctorsAshaWorkers)
      ?.then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e?.resource?.resourceType === forms?.practitioner
        )
        const roles: { resource: PractitionerRole }[] =
          res?.data?.entry?.filter(
            (e: { resource: { resourceType: string } }) =>
              e?.resource?.resourceType === forms?.practitionerRole
          )
        const data: FormDoctor[] = practitioners?.map(
          (e: { resource: Practitioner }) => {
            const role = roles?.find(
              (r) =>
                r?.resource?.practitioner?.reference?.split?.('/')?.[1] ===
                e?.resource.id
            )
            return {
              id: e?.resource?.id,
              firstName:
                e?.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString,
              middleName: emptyString,
              lastName: e?.resource?.name?.[0]?.family ?? emptyString,
              salutation: e?.resource?.name?.[0]?.prefix?.[0] ?? emptyString,
              registeredOn:
                e?.resource?.identifier?.[1]?.period?.start ?? emptyString,
              dob: e?.resource?.birthDate,
              email:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues?.email
                )?.value || formValues?.na,
              phone:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues?.phone
                )?.value || formValues?.na,
              qualification:
                e?.resource?.qualification?.[0]?.code?.text || formValues?.na,
              gender: e?.resource?.gender || formValues?.na,
              regNumber:
                e?.resource?.qualification?.[0]?.identifier?.[0]?.value,
              speciality:
                formatDoctors(roles, e?.resource?.id) || formValues?.na,
              active: e?.resource?.active ?? true,
              operatingUnit: e?.resource?.address?.map((d: any) => {
                return d?.text
              })?.[0],
              healthService:
                role?.resource?.healthcareService?.map?.(
                  (hs) => hs?.reference?.split?.('/')?.[1] ?? emptyString
                ) ?? [],
            }
          }
        )
        data.sort((a, b) => (a?.id < b?.id ? -1 : 1))
        setDoctorsData(data)
      })
      ?.catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    fetchDoctorsAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter])

  const filteredDoctors = doctorsData
    ?.filter((doctor) => {
      const isNameSearch =
        doctor.firstName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        doctor.lastName.toLowerCase().startsWith(searchText.toLowerCase())

      const isStatusMatched =
        statusFilter === formValues.all ||
        (statusFilter === formValues.active && doctor.active) ||
        (statusFilter === formValues.inActive && !doctor.active)

      return isNameSearch && isStatusMatched
    })
    ?.filter((doctor) => {
      return (
        doctor.firstName.trim() !== emptyString ||
        doctor.lastName.trim() !== emptyString
      )
    })
  filteredDoctors?.sort?.(
    (a: { firstName: string }, b: { firstName: string }) =>
      a.firstName?.toLowerCase() < b.firstName?.toLowerCase() ? -1 : 1
  )

  const handleCreate = () => {
    navigate(Create)
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`${Edit}/${id}`)
    }
  }
  const handleStatusFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(event?.target?.value)
  }

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setDoctor(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setDoctor(null)
  }

  return (
    <>
      <div className='button-enclosure flexible mb6 mtminus8'>
        <div className='filterandtext'>
          <div className='membershipText'>{doctors}</div>
        </div>
        <div className='status-flex'>
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className='status-filter doctor-status'
          >
            <option value={formValues?.active}>{forms?.active}</option>
            <option value={formValues?.inActive}>{forms?.inactive}</option>
          </select>
          <input
            type='search'
            className='admin-search mr-none'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder={placeHolder?.doctorName}
          />
          <CreateButton title={'Create'} onClick={handleCreate} />
        </div>
      </div>
      <div
        ref={patientTableContainerRef}
        className='data-table table-fixed admin-table-table-container'
        style={{ height: '440px', overflowY: 'auto' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-10'>{tableText?.slNo}</th>
              <th className='text-left table-w-25'>{tableText?.name}</th>
              <th className='text-left table-w-20'>{tableText?.speciality}</th>
              <th className='text-left table-w-20'>
                {tableText.qualification}
              </th>
              <th className='text-left table-w-20'>{tableText?.regNo}</th>
              <th className='table-w-20'>{tableText?.email}</th>
              <th className='text-left table-w-20'>{tableText?.phone}</th>
              <th className='text-left table-w-15'>{tableText?.gender}</th>
              <th className='text-left table-w-20'>
                {tableText?.operatingUnit}
              </th>
              <th className='text-left table-w-20'>{tableText?.createdBy}</th>
              <th className='text-left table-w-20'>{tableText?.updatedBy}</th>
              <th className='text-left table-w-20'>{tableText?.action}</th>
            </tr>
          </thead>
          <tbody>
            {filteredDoctors.length > 0 ? (
              filteredDoctors?.map((user, index) => (
                <tr key={index}>
                  <td className='table-w-4'>{index + 1}</td>
                  <td className='text-left table-w-15'>{`${user?.salutation} ${user?.firstName} ${user?.lastName}`}</td>
                  <td className='text-left table-w-20'>
                    {user?.speciality.split('::')[0]}
                  </td>
                  <td className='text-center table-w-20'>
                    {user?.qualification}
                  </td>
                  <td className='text-left table-w-10 wordBreak'>
                    {user?.regNumber}
                  </td>
                  <td className='table-w-40 wordBreak normal'>{user?.email}</td>
                  <td className='text-left table-w-15'>{user?.phone}</td>
                  <td className='text-left'>{user?.gender}</td>
                  <td>{user?.operatingUnit}</td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={user?.registeredOn}
                      name={provenances?.[user?.id]?.createdBy}
                      mode={create}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      date={provenances?.[user?.id]?.updatedOn}
                      name={provenances?.[user?.id]?.updatedBy}
                      mode={update}
                    />
                  </td>
                  <td className='edit-relation'>
                    <div className='set-action-icons'>
                      {user?.active ? (
                        <IconButton
                          title='Archive'
                          size='small'
                          onClick={() =>
                            Archive(user, setDoctorsData, menuValues?.Doctor)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <ArchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton
                          title='Un-Archive'
                          size='small'
                          onClick={() =>
                            Archive(user, setDoctorsData, menuValues?.Doctor)
                          }
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <UnarchiveOutlinedIcon fontSize='small' />
                        </IconButton>
                      )}
                      <IconButton
                        title='Delete'
                        onClick={() => handleDeleteDialogOpen(user)}
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <DeleteOutlined />
                      </IconButton>
                      <EditButton
                        title='Edit Doctor'
                        onEdit={() => handleEdit(user?.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className='text-center'>
                  {tableText?.noDoctorsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={doctor}
          name={doctor?.firstName + ' ' + doctor?.lastName}
          setData={setDoctorsData}
          item={menuValues?.Doctor}
        />
      )}
    </>
  )
}

export default Doctors
