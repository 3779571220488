import { useAppDispatch } from '../../../app/hooks'
import { useNavigate } from 'react-router-dom'
import KeycloakService from '../../../utils/keycloakService'
import { PATIENTS_WRITE } from '../../../utils/roles'
import SelectableSearch from '../../../components/SelectableSearch'
import {
  fetchPatientsAsync,
  setPatientSearchQueryValue,
  setRedirectTo,
} from '../patientsSlice'
import { create } from '../../Radiology/constants'
import FilterButton from '../filter/Filter'
import { OPRegistration } from '../constants'

const PatientSearch = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSearch = (query: string, param: string) => {
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    dispatch(setPatientSearchQueryValue({ query: q, value: param }))
    dispatch(fetchPatientsAsync())
  }

  const registerPatient = () => {
    if (KeycloakService.hasRole([PATIENTS_WRITE])) {
      dispatch(setRedirectTo('patients'))
      navigate('/patient-registration')
    }
  }

  return (
    <div className='patient-filter' style={{ marginTop: '-4px' }}>
      <div className='membershipText' style={{ marginBottom: '13px' }}>
        {OPRegistration}
      </div>
      <div className='patient-search' style={{ paddingBottom: '10px' }}>
        <FilterButton />
        <SelectableSearch
          items={['ID', 'Phone', 'Name']}
          onSearch={handleSearch}
        />
        <button
          className={`btn btn-primaryBtn ml10 ${
            KeycloakService.hasRole([PATIENTS_WRITE]) ? '' : 'auth-disabled'
          }`}
          onClick={registerPatient}
        >
          {create}
        </button>
      </div>
    </div>
  )
}

export default PatientSearch
