import { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import LinkButton from './LinkButton'
import '../../lms.scss'
import ProvenanceView from '../../../../components/ProvenanceView'
import {
  create,
  update,
  testRangeTableHeadings,
  menuValues,
} from '../../constants'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { activeText } from '../../../Radiology/constants'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const TestRangeTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [testRangeData, setTestRangeData] = useState<any>({})
  const filtered = tableData?.filter((d: any) => {
    const name = d.lab_test?.name
      ?.toLowerCase()
      ?.includes(searchParam.toLowerCase())
    const rangeStatus = d?.status === status
    return name && rangeStatus
  })
  filtered?.sort?.((a: any, b: any) => {
    const p1 = a.lab_test?.panel
    const p2 = b.lab_test?.panel
    if (p1?.name < p2?.name) {
      return -1
    } else if (p1?.name > p2?.name) {
      return 1
    } else {
      if (a.lab_test?.name?.toLowerCase() < b.lab_test?.name?.toLowerCase()) {
        return -1
      } else if (
        a.lab_test?.name?.toLowerCase() > b.lab_test?.name?.toLowerCase()
      ) {
        return 1
      } else {
        return a.id < b.id ? -1 : 1
      }
    }
  })

  const paramRangeTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramRangeTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    setTableData(data)
  }, [data])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setTestRangeData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setTestRangeData(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-containe'
      ref={paramRangeTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>{testRangeTableHeadings?.id}</th>
            <th className='table-w-20'>
              {testRangeTableHeadings?.parameterName}
            </th>
            <th className='table-w-20'>{testRangeTableHeadings?.testName}</th>
            <th>{testRangeTableHeadings?.gender}</th>
            <th>{testRangeTableHeadings?.method}</th>
            <th>{testRangeTableHeadings?.unitType}</th>
            <th>{testRangeTableHeadings?.ageRange}</th>
            <th>{testRangeTableHeadings?.valueRange}</th>
            <th className='table-w-7'>{testRangeTableHeadings?.createdBy}</th>
            <th className='table-w-7'>{testRangeTableHeadings?.updatedBy}</th>
            <th className='table-w-8'>{testRangeTableHeadings?.actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any) => (
              <tr key={d?.id}>
                <td>{d?.id}</td>
                <td>
                  <LinkButton
                    id={d?.id}
                    name={d?.lab_test.name}
                    onClick={onEdit}
                  />
                </td>
                <td>{d?.lab_test.panel?.name ?? '-'}</td>
                <td>{d?.gender ?? '-'}</td>
                <td>{d?.test_method?.method_name ?? '-'}</td>
                <td>{d?.test_unit_type?.unit_type ?? '-'}</td>
                <td>
                  {d?.min_age || d?.max_age ? (
                    <span>
                      {d.min_age ?? 0} to{' '}
                      {d.max_age ?? (
                        <span style={{ fontSize: 16 }}>&infin;</span>
                      )}{' '}
                      {d.age_type ?? 'y'}
                    </span>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {d?.min_value || d?.max_value ? (
                    <span>
                      {d.min_value ?? 0} to {d.max_value}
                    </span>
                  ) : d.abnormal ? (
                    <span>{d.abnormal}</span>
                  ) : d?.ref_range ? (
                    <div className='range-column'>
                      {d?.ref_range
                        ?.split(',')
                        ?.map((rangeValue: any, i: number) => (
                          <span key={i}>{rangeValue?.trim()}</span>
                        ))}
                    </div>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  <ProvenanceView
                    mode={create}
                    date={d?.created_date ?? ''}
                    name={d?.created_by ?? '-'}
                  />
                </td>
                <td>
                  <ProvenanceView
                    mode={update}
                    date={d?.updated_date ?? ''}
                    name={d?.updated_by ?? '-'}
                  />
                </td>
                <td>
                  <div className='set-action-icons'>
                    {d?.status === activeText ? (
                      <IconButton
                        title='Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.ParameterRange)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <ArchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <IconButton
                        title='un-Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.ParameterRange)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <UnarchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton
                      title='Delete'
                      size='small'
                      onClick={() => handleDeleteDialogOpen(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No Test Ranges Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={testRangeData}
          name={testRangeData?.lab_test?.name}
          setData={setTableData}
          item={menuValues?.ParameterRange}
        />
      )}
    </div>
  )
}

export default TestRangeTable
