import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'
import ProvenanceView from '../../../../components/ProvenanceView'
import {
  create,
  update,
  labTestTableHeadings,
  menuValues,
} from '../../constants'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { activeText } from '../../../Radiology/constants'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const LabTestTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [parameterData, setParameterData] = useState<any>({})
  let filtered = getSearchResults(tableData, searchParam)
  filtered = filtered?.filter((d) => d?.status === status)

  filtered?.sort?.((a: any, b: any) => {
    if (a.department?.department_name < b.department?.department_name) {
      return -1
    } else if (a.department?.department_name > b.department?.department_name) {
      return 1
    } else {
      if (a.panel?.name < b.panel?.name) {
        return -1
      } else if (a.panel?.name > b.panel?.name) {
        return 1
      } else {
        return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
      }
    }
  })

  const paramTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    setTableData(data)
  }, [data])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setParameterData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setParameterData(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={paramTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>{labTestTableHeadings?.id}</th>
            <th className='table-w-20'>
              {labTestTableHeadings?.parameterName}
            </th>
            <th>{labTestTableHeadings?.parameter_short_form}</th>
            <th>{labTestTableHeadings?.department}</th>
            <th className='table-w-15'>{labTestTableHeadings?.sample}</th>
            <th>{labTestTableHeadings?.test}</th>
            <th className='table-w-10'>{labTestTableHeadings?.resultType}</th>
            <th className='table-w-8'>{labTestTableHeadings?.createdBy}</th>
            <th className='table-w-8'>{labTestTableHeadings?.updatedBy}</th>
            <th className='table-w-8'>{labTestTableHeadings?.actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any) => (
              <tr key={d?.id}>
                <td>{d?.id}</td>
                <td>
                  <LinkButton id={d?.id} name={d?.name} onClick={onEdit} />
                </td>
                <td>{d?.parameter_short_form}</td>
                <td>{d?.department?.department_name ?? '-'}</td>
                <td>{d?.panel?.sample?.name ?? '-'}</td>
                <td>{d?.panel?.name ?? '-'}</td>
                <td>{d?.test_result_type?.result_type ?? '-'}</td>
                <td>
                  <ProvenanceView
                    mode={create}
                    date={d?.created_date ?? ''}
                    name={d?.created_by ?? ''}
                  />
                </td>
                <td>
                  <ProvenanceView
                    mode={update}
                    date={d?.updated_date ?? ''}
                    name={d?.updated_by ?? ''}
                  />
                </td>
                <td title='Delete'>
                  <div className='set-action-icons'>
                    {d?.status === activeText ? (
                      <IconButton
                        title='Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Parameter)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <ArchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <IconButton
                        title='un-Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Parameter)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <UnarchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton
                      title='Delete'
                      size='small'
                      onClick={() => handleDeleteDialogOpen(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center'>
                No Test Parameters Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={parameterData}
          name={parameterData?.name}
          setData={setTableData}
          item={menuValues?.Parameter}
        />
      )}
    </div>
  )
}

export default LabTestTable
