import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from 'react'
import {
  MembershipModel,
  PaginateModel,
  membershipTableHeadings,
} from './models'
import { startSxpProxy } from '../../utils/api'
import '../membership/membership.scss'
import { ADMIN_PROJECT_ID, MEMBERSHIP_PROJECT_ID } from '../../utils/constants'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import { NavLink, useNavigate } from 'react-router-dom'
import MembershipSearch from './MembershipSearch'
import { MEMBERSHIP_UPDATE } from '../../utils/roles'
import KeycloakService from '../../utils/keycloakService'
import Edit from '../../assets/icons/edit_icon.svg'
import MaskedNumber from '../../components/MaskedNumber'
import { selectQuery, selectParam, setQueryParam } from './membershipSlice'
import { membership } from '../Radiology/constants'
import { odooPharmacyLink } from '../appointments/models'
import ProvenanceView from '../../components/ProvenanceView'
import { create, update } from '../lms/constants'

const Membership = () => {
  const query = useAppSelector(selectQuery)
  const param = useAppSelector(selectParam)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [paginate, setPaginate] = useState<PaginateModel>({
    totalCount: 0,
    totalPages: 0,
    data: [],
  })
  const [memberships, setMemberships] = useState<MembershipModel[]>([])
  const filteredMemberships = memberships.filter(
    (e) => e.partner?.ref !== false
  )
  const location = useAppSelector(selectSelectedLocationId)
  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [limit, paginate.totalCount])
  const getMemberships = useCallback(
    (q: any, p: any) => {
      const state = {
        location,
        [q]: p,
        limit: limit,
        offset: (currentPage - 1) * limit,
      }
      startSxpProxy(MEMBERSHIP_PROJECT_ID, 'membershipLines', state)
        .then((data) => {
          setPaginate({
            totalCount: data?.data?.total_count,
            totalPages: data?.data?.total_pages,
            data: data?.data?.data,
          })
          const members: MembershipModel[] = data?.data?.data ?? []
          setMemberships(members)
        })
        .catch((err) => {
          console.log(err)
          setMemberships([])
          setPaginate({
            totalCount: 0,
            totalPages: 0,
            data: [],
          })
        })
        .finally(() => setLoading(false))
    },
    [location, currentPage, limit]
  )
  const index = (currentPage - 1) * limit
  const startIndex = index + 1
  const endIndex = Math.min(index + limit, paginate.totalCount)
  useEffect(() => {
    setLoading(true)
    getMemberships(query, param)
  }, [getMemberships, query, param])

  const membershipTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = membershipTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const handleCreateMembership = () => {
    navigate('/membership/create')
  }
  const openOdooInvoice = (id: any) => {
    startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: location,
    })
      .then((data) => {
        const url = data?.data?.auth_link
        window.open(url, '_blank', 'noopener,noreferrer')
      })
      .catch((err) => console.error(err, 'err'))
  }
  const onEdit = (id: any) => {
    navigate(`/membership-edit/${id}`)
  }
  const statusFunction = (id: string) => {
    switch (id) {
      case 'paid':
        return 'Paid Member'
      case 'none':
        return 'Non Member'
      case 'waiting':
        return 'Waiting Member'
      case 'invoiced':
        return 'Invoiced Member'
      case 'canceled':
        return 'Cancelled Member'
      case 'old':
        return 'Old Member'
      default:
        return 'Others'
    }
  }
  const dateCheck = (date: string) => {
    const d1 = new Date(date)
    const d2 = new Date()
    if (d1 >= d2) {
      return 'Active'
    } else {
      return 'Expired'
    }
  }
  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(e.target.value))
  }

  return (
    <div className='layoutcontent'>
      <div className='membershipText'>{membership}</div>
      <MembershipSearch onCreate={handleCreateMembership} />
      <div
        className='appointments-table-container'
        ref={membershipTableContainerRef}
      >
        <table
          className='data-table admin-table'
          style={{ position: 'relative' }}
        >
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr style={{ textAlign: 'center' }}>
              <th className='table-w-3'>{membershipTableHeadings?.slno}</th>
              <th>{membershipTableHeadings?.MembershipId}</th>
              <th className='table-w-10'>{membershipTableHeadings?.uhid}</th>
              <th className='text-left'>{membershipTableHeadings?.primary}</th>
              <th className='table-w-8'>{membershipTableHeadings?.mobileNo}</th>
              <th className='table-w-6'>
                {membershipTableHeadings?.operatingUnit}
              </th>
              <th>{membershipTableHeadings?.membership}</th>
              <th className='table-w-7'>{membershipTableHeadings?.memType}</th>
              <th className='table-w-7'>{membershipTableHeadings?.validity}</th>
              <th className='table-w-7'>
                {membershipTableHeadings?.memStatus}
              </th>
              <th className='table-w-7'>
                {membershipTableHeadings?.createdBy}
              </th>
              <th className='table-w-7'>
                {membershipTableHeadings?.updatedBy}
              </th>
              <th className='table-w-7'>
                {membershipTableHeadings?.payStatus}
              </th>
              <th className='width-minor'>{membershipTableHeadings?.edit}</th>
            </tr>
          </thead>
          <tbody>
            {filteredMemberships?.length > 0 ? (
              filteredMemberships?.map((d, i) => (
                <tr key={d?.id}>
                  <td className='text-center'>{index + i + 1}</td>
                  <td className='text-center'>
                    {' '}
                    <NavLink to={`/membership-dashboard/${d?.id}`}>
                      {d?.name}
                    </NavLink>
                  </td>
                  <td>{d?.partner?.ref}</td>
                  <td>{d?.partner?.name}</td>
                  <td className='text-center'>
                    {d?.partner?.phone ? (
                      <MaskedNumber mobile={d?.partner?.phone} />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {
                      d?.account_invoice_id?.operating_unit_id?.[1]?.split(
                        ' '
                      )?.[1]
                    }
                  </td>
                  <td className='text-center'>{d.membership_id?.[1]}</td>
                  <td className='text-center'>{statusFunction(d?.state)}</td>
                  <td className='text-center'>
                    {d?.date_to ? d?.date_to : '-'}
                  </td>
                  <td className='text-center'>
                    {d?.date_to ? dateCheck(d?.date_to) : '-'}
                  </td>
                  <td>
                    <ProvenanceView
                      mode={create}
                      date={d?.account_invoice_id?.invoice_date_time ?? ''}
                      name={d?.account_invoice_id?.thop_user_name ?? ''}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      mode={update}
                      date={d?.write_date ?? ''}
                      name={d?.thop_last_updated_user_name ?? ''}
                    />
                  </td>
                  <td className='text-center'>
                    {d?.state === 'invoiced' || d?.state === 'waiting' ? (
                      <span
                        style={{
                          padding: '2px 4px',
                          backgroundColor: 'teal',
                          color: 'white',
                          borderRadius: 2,
                          marginLeft: 6,
                          fontSize: 11,
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          openOdooInvoice(d?.account_invoice_id?.id)
                        }
                      >
                        Unpaid
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='text-center appointmentBtn'>
                    <div
                      onClick={() =>
                        KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? onEdit(d?.id)
                          : undefined
                      }
                      title='Edit Patient'
                      className={`custom-btn custom-btn-primary ml10 ${
                        KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? ''
                          : 'auth-disabled'
                      }`}
                      style={{
                        cursor: KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? 'pointer'
                          : 'default',
                      }}
                    >
                      <img
                        alt='Edit Patient'
                        src={Edit}
                        style={{ height: '18px', width: '20px' }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center' }}>
                  {loading
                    ? 'Loading Memberships'
                    : 'No Memberships found with uhid'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='text-right'>
        <div className='inline-block pagination text-right'>
          <span>
            <span className='mr10'>
              Showing <b>{startIndex}</b> to <b>{endIndex}</b> of{' '}
              <b>{paginate?.totalCount}</b>{' '}
            </span>
            <select
              className='rowsperpage'
              defaultValue={limit}
              onChange={handleSizeChange}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size}>{size}</option>
              ))}
            </select>
          </span>
        </div>

        <div className='inline-block' style={{ marginRight: '80px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className={`mr10 btn btn-primaryBtn ${
              currentPage !== 1 ? '' : 'disableBtn'
            }`}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className={`btn btn-primaryBtn ${
              currentPage !== paginate?.totalPages ? '' : 'disableBtn'
            }`}
            disabled={currentPage === paginate?.totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default Membership
