import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import KeycloakService from '../../utils/keycloakService'
import Asterisk from '../../components/Asterisk'
import { emptyString } from '../Radiology/constants'
import {
  CreatePartnerForm,
  forms,
  formValues,
  intent,
  OrganizationIntent,
  PackageErrorMsg,
} from '../administration/constants'

const PartnerForm = ({
  partner,
  isEdit,
}: {
  partner?: any
  isEdit?: boolean
}) => {
  const navigate = useNavigate()
  const initialFormState = {
    type: 'b2bPartner',
    name: '',
    phoneNumber: '',
    status: '',
    fhirId: '',
    id: '',
    govId: '',
    email: '',
  }
  const [partnerData] = useState(partner ?? initialFormState)
  const { values, handleBlur, handleChange, submitForm } = useFormik({
    initialValues: partnerData,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      handleRegistrationSubmit(values)
    },
    enableReinitialize: true,
  })
  const handleRegistrationSubmit = async (data: Record<string, any>) => {
    const { name, phoneNumber, email, fhirId, id } = data
    if (!name || !phoneNumber || !email || (!fhirId && !id)) {
      toast?.error(PackageErrorMsg?.b2bErrorMsg)
      return
    }
    const formatted = {
      type: intent?.b2bPartner,
      name: name,
      email: email,
      mobile_number: phoneNumber,
      fhir_id: fhirId,
      ...(id ? {} : { created_by: KeycloakService?.getUsername() }),
      updated_by: KeycloakService?.getUsername(),
      ...(id && { id: id }),
      status: id ? data.status : formValues?.active,
      ...(id && { odoo_partnerId: values?.odoo_partner_id }),
      ...(id && { date_changed: new Date()?.toISOString() }),
    }

    const state = {
      organization: formatted,
    }
    const operation = id
      ? OrganizationIntent?.editOrganization
      : OrganizationIntent?.createOrganization
    const statebody = id ? formatted : state
    startSxpProxy(ADMIN_PROJECT_ID, operation, statebody)
      ?.then((data) => {
        toast?.success(data?.data?.message)
        navigate('/administration/referredOut')
      })
      .catch(() => {
        toast?.error(data?.data?.message)
      })
  }

  return (
    <>
      <div>
        <form className='panel-form'>
          <div className='admin-form-flex w-72'>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                {CreatePartnerForm?.Name}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='name'
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                {CreatePartnerForm?.PhoneNumber}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='phoneNumber'
                value={values?.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                {CreatePartnerForm?.EmailId}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='email'
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {isEdit && (
              <div className='doc-container'>
                <label htmlFor='test-name' className='doc-input-label'>
                  {CreatePartnerForm?.Status}
                  <Asterisk />
                  &nbsp;:
                </label>
                <select
                  className='panel-input-content'
                  required
                  name='status'
                  value={values?.status}
                  onChange={handleChange}
                >
                  <option value={emptyString}>
                    {CreatePartnerForm?.SelectOption}
                  </option>
                  <option value={forms?.active}>{forms?.active}</option>
                  <option value={forms?.inactive}>{forms?.inactive}</option>
                </select>
              </div>
            )}
            <div className='doc-container'>
              <div className='doc-input-label'></div>
              <div className='panel-input-content'>
                <Box className='box-btn-referred'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    className='ip-register-btn w10'
                    onClick={submitForm}
                  >
                    {CreatePartnerForm?.Submit}
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default PartnerForm
