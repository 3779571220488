import { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { getRoomResults } from '../../utils'
import { roomType, create, update, menuValues } from '../../constants'
import {
  emptyString,
  inactiveText,
  activeText,
} from '../../../Radiology/constants'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
  selectedLmsStatus,
} from '../../lmsSlice'
import LinkButton from './LinkButton'
import '../../lms.scss'
import ProvenanceView from '../../../../components/ProvenanceView'
import { IconButton } from '@mui/material'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import KeycloakService from '../../../../utils/keycloakService'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'

const Rooms = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [roomData, setRoomData] = useState<any>({})
  const status = useAppSelector(selectedLmsStatus)
  let filtered = getRoomResults(tableData, searchParam, locationSearch)
  filtered = filtered?.filter((d: any) => {
    const roomStatus = d?.active ? activeText : inactiveText
    return status === roomStatus
  })
  filtered?.sort?.((a: { name: string }, b: { name: string }) =>
    a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
  )
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setRoomData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setRoomData(null)
  }

  useEffect(() => {
    setTableData(data)
  }, [data, status])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={sampleTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead className='sticky'>
          <tr>
            <th className='table-w-5'>{roomType?.id}</th>
            <th>{roomType?.roomName}</th>
            <th>{roomType?.wardName}</th>
            <th className='table-w-10'>{roomType?.floor}</th>
            <th className='table-w-10'>{roomType?.location}</th>
            <th className='table-w-8'>{roomType?.createdBy}</th>
            <th className='table-w-8'>{roomType?.updatedBy}</th>
            <th className='table-w-8'>{roomType?.actions}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d?.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.room_name} onClick={onEdit} />
                </td>
                <td>{d?.ward_detail?.ward_name}</td>
                <td>{d.floor_number ?? emptyString}</td>
                <td>{d?.ward_detail?.location_id}</td>
                <td>
                  <ProvenanceView
                    mode={create}
                    date={d?.date_created ?? ''}
                    name={d?.created_by ?? '-'}
                  />
                </td>
                <td>
                  <ProvenanceView
                    mode={update}
                    date={d?.date_changed ?? ''}
                    name={d?.updated_by ?? '-'}
                  />
                </td>
                <td>
                  <div className='set-action-icons'>
                    {d?.active ? (
                      <IconButton
                        title='Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Room)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <ArchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    ) : (
                      <IconButton
                        title='un-Archive'
                        size='small'
                        onClick={() =>
                          Archive(d, setTableData, menuValues?.Room)
                        }
                        disabled={
                          !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                        }
                      >
                        <UnarchiveOutlinedIcon fontSize='small' />
                      </IconButton>
                    )}
                    <IconButton
                      title='Delete'
                      size='small'
                      onClick={() => handleDeleteDialogOpen(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {roomType.noRooms}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={roomData}
          name={roomData?.room_name}
          setData={setTableData}
          item={menuValues?.Room}
        />
      )}
    </div>
  )
}

export default Rooms
