export const deletePhoto = 'Delete Photo'
export const profilePicture = 'Profile Picture'
export const captureImage = 'Capture Image'
export const uploadImage = 'Upload Photo'
export const doctorImage = 'Image'
export const doctorSignature = 'Signature'
export const captureMode = 'capture'
export const uploadMode = 'upload'
export const crhealthcare =
  'CR Healthcare Services Pvt Ltd* (CIN - U85110TG2021PTC153300)'
export const departments = 'Departments'
export const doctors = 'Doctors'
export const locationtext = 'Locations'
export const pathologist = 'Pathologist'
export const CreateMembership = 'Create Membership'
export const LoadingMsg = 'Creating...'
export const sxpElements = {
  town: 'town',
  postalCode: 'postalCode',
}
