/* eslint-disable camelcase */
import React from 'react'
import PackageOrder from './packageOrder'

export type PackageItem = {
  name?: string
  price?: string
  from?: string
  till?: string
  odooPackageId?: any
  id?: any
}

const Packages = () => {
  return (
    <>
      <PackageOrder />
    </>
  )
}

export default Packages
