/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Patient } from 'fhir/r4'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { toast } from 'react-toastify'
import { toastOptions } from '../../models'
import { useAppDispatch } from '../../../../app/hooks'
import { setLmsActiveTab, setLmsDateFilter } from '../../lmsSlice'
import { LabMode, LabOrder } from '../../../labTests/models'

import ToastMessage from '../ToastMessage'
import LabTests from '../../../labTests/LabTests'
import PatientInfo from '../PatientInfo'

const LabReference = () => {
  const { id } = useParams()
  const [patient, setPatient] = useState<Patient | null>(null)
  const [order, setOrder] = useState<LabOrder>({})
  const [requestedBy, setRequestedBy] = useState('')
  const [mode, setMode] = useState<LabMode>('test')
  const [origin] = useState('')
  // const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const locationId = useAppSelector(selectSelectedLocationId)
  // const location = useAppSelector((state) =>
  //   selectLocationById(locationId, state)
  // )

  // const updateSaveButtonState = useCallback(
  //   (order: LabOrder, requestedBy: string, origin: string) => {
  //     const isDisabled =
  //       !requestedBy || !origin || (mode === 'test' && testCount(order) === 0)
  //     // setIsSaveDisabled(isDisabled)
  //   },
  //   [mode]
  // )

  useEffect(() => {
    startSxpProxy(LABS_PROJECT_ID, 'getLabOrdersByPatientId', { patientId: id })
      .then((data) => {
        const details = data.data?.lab_orders?.[0] ?? {}
        setOrder(details)
        setRequestedBy(details.requested_by)
        setMode('test')
        // updateSaveButtonState(details, details.requested_by, origin)
      })
      .catch((err) => {
        console.log(err)
      })
    startSxpProxy(OPD_PROJECT_ID, 'getPatientApi', { id: id })
      .then((data) => {
        setPatient(data.data ?? null)
      })
      .catch((err) => {
        console.error(err)
        setPatient(null)
      })
  }, [id, origin])

  const handleSave = () => {
    toast(<ToastMessage message={'Lab Tests Saved'} />, {
      ...toastOptions,
      type: 'success',
    })
    setTimeout(() => {
      dispatch(setLmsDateFilter(new Date().toISOString().slice(0, 10)))
      dispatch(setLmsActiveTab('collection'))
      navigate('/lms/dashboard')
    }, 1500)
  }

  return (
    <div className='create-lab-order'>
      {patient ? <PatientInfo patient={patient} /> : null}
      <div className='refference-header'>
        <div className='membershipText mt10'>
          <span className='ml10'> Lab Test Refference </span>
        </div>
      </div>
      <div className='package-container ml10'>
        <div className='LabTestRefference'>
          {mode === 'test' && (
            <div>
              <div className='lab-container LabTestRefferenceContainer'>
                <LabTests
                  id={id ?? ''}
                  patientId={id ?? ''}
                  order={order}
                  mode='patient'
                  onSave={handleSave}
                  requestedBy={requestedBy}
                  origin={origin}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LabReference
