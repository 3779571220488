import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateButton from '../../components/CreateButton'
import EditButton from '../../components/EditButton'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import {
  create,
  forms,
  OrganizationIntent,
  PartnerTable,
  update,
  formValues,
} from '../administration/constants'
import ProvenanceView from '../../components/ProvenanceView'
import { IconButton } from '@mui/material'
import KeycloakService from '../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../utils/roles'
import { DeleteOutlined } from '@mui/icons-material'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import { Archive } from '../lms/components/admin/Archive'
import { DeleteConfirmationDialog } from '../lms/components/admin/DeleteConfirmationDialog'
import { menuValues } from '../lms/constants'
import { activeText } from '../Radiology/constants'
import { create as routeCreate, edit } from '../bedManagement/constants'

const Organization = () => {
  const [partnerData, setPartnerData] = useState<[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState(activeText)
  const navigate = useNavigate()
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [organization, setOrganization] = useState<any>({})
  const handleCreate = () => {
    navigate(routeCreate)
  }

  const getAllOrganizations = () => {
    setLoading(true)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      OrganizationIntent?.getAllOrganizationForAdmin,
      {}
    )
      ?.then((data: any) => {
        const organization = data?.data?.organization
        if (organization?.length > 0) {
          setPartnerData(organization)
        } else {
          setPartnerData([])
        }
        setLoading(false)
      })
      .catch(() => {
        setPartnerData([])
        setLoading(false)
      })
  }
  useEffect(() => {
    getAllOrganizations()
  }, [statusFilter])
  const handleEdit = (id: any) => {
    navigate(`${edit}/${id}`)
  }
  const filteredPartnerData = partnerData?.filter(
    (partner: any) =>
      partner?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) &&
      partner?.status === statusFilter
  )

  function handleDeleteDialogOpen(data: any) {
    setDeleteDialog(true)
    setOrganization(data)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setOrganization(null)
  }

  const handleStatusFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(event?.target?.value)
  }

  return (
    <>
      <div>
        <div className='button-enclosure border-none mb9 flexible'>
          <div>
            <span className='membershipText'>{PartnerTable?.Partner}</span>
          </div>
          <div className='status-flex'>
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className='status-filter doctor-status'
            >
              <option value={formValues?.active}>{forms?.active}</option>
              <option value={formValues?.inActive}>{forms?.inactive}</option>
            </select>
            <input
              type='search'
              className='admin-search'
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              placeholder='Search by Name'
            />
            <CreateButton title={'Create'} onClick={handleCreate} />
          </div>
        </div>
        <div>
          <table className='data-table table-fixed admin-table relative'>
            <thead className='admin-table-head sticky'>
              <tr>
                <th className='table-w-5'>{PartnerTable?.SerialNo}</th>
                <th className='table-w-5'>{PartnerTable?.OdooId}</th>
                <th className='table-w-10'>{PartnerTable?.Partner_Type}</th>
                <th className='table-w-15'>{PartnerTable?.Partner_Name}</th>
                <th className='table-w-10'>{PartnerTable?.PhoneNumber}</th>
                <th className='table-w-10'>{PartnerTable?.EmailId}</th>
                <th className='table-w-8'>{PartnerTable?.CreatedBy}</th>
                <th className='table-w-8'>{PartnerTable?.UpdatedBy}</th>
                <th className='table-w-8'>{PartnerTable?.Action}</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>{forms?.loading}</td>
                </tr>
              ) : partnerData?.length === 0 ? (
                <tr>
                  <td colSpan={6}>{PartnerTable?.apierrorMsg}</td>
                </tr>
              ) : (
                filteredPartnerData?.map((e: any, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{e?.odoo_partner_id}</td>
                    <td>{e?.type ?? '-'}</td>
                    <td>{e?.name ?? '-'}</td>
                    <td>{e?.mobile_number ?? '-'}</td>
                    <td>{e?.email}</td>
                    <td className='text-center'>
                      <ProvenanceView
                        date={e?.date_created}
                        name={e?.created_by}
                        mode={create}
                      />
                    </td>
                    <td className='text-center'>
                      <ProvenanceView
                        date={e?.date_changed}
                        name={e?.updated_by}
                        mode={update}
                      />
                    </td>
                    <td className='edit-relation'>
                      <div className='set-action-icons'>
                        {e?.status === activeText ? (
                          <IconButton
                            title='Archive'
                            size='small'
                            onClick={() =>
                              Archive(e, setPartnerData, menuValues?.Partner)
                            }
                            disabled={
                              !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                            }
                          >
                            <ArchiveOutlinedIcon fontSize='small' />
                          </IconButton>
                        ) : (
                          <IconButton
                            title='Un-Archive'
                            size='small'
                            onClick={() =>
                              Archive(e, setPartnerData, menuValues?.Partner)
                            }
                            disabled={
                              !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                            }
                          >
                            <UnarchiveOutlinedIcon fontSize='small' />
                          </IconButton>
                        )}
                        <IconButton
                          title='Delete'
                          onClick={() => handleDeleteDialogOpen(e)}
                          disabled={
                            !KeycloakService?.hasRole([SUPER_ADMIN_DELETE])
                          }
                        >
                          <DeleteOutlined />
                        </IconButton>
                        <EditButton
                          title='Edit Doctor'
                          onEdit={() => handleEdit(e?.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={organization}
          name={organization?.name}
          setData={setPartnerData}
          item={menuValues?.Partner}
        />
      )}
    </>
  )
}
export default Organization
