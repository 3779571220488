export const getEncounter = 'getEncounter'
export const visitType = 'visit-type'
export const general = 'general'
export const dental = 'dental'
export const physio = 'physio'
export const odooPharmacyLink = 'odooPharmacyLink'
export const getVisitsByDateIntent = 'getVisitsByDate'
export const visitsCountOP = 'visitsCountOP'
export const fetchAppointments = 'fetchAppointments'
export const PageSize = [10, 20, 50, 100]
