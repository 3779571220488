import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { Provenance, Practitioner } from 'fhir/r4'
import { getProvenanceData } from '../../patients/utils'
import { ProvenanceData } from '../../patients/patientsSlice'
import { ColDef, INumberFilterParams } from 'ag-grid-community'
import { toast } from 'react-toastify'
import MedAgDataGrid from '../../../components/MedAgDataGrid'
import { startSxpProxy } from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'

import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import {
  CreatePartnerForm,
  forms,
  UserManagementIntent,
  UserManagementMessages,
} from '../constants'
import UserData from './UserData'
import ProvenanceView from '../../../components/ProvenanceView'
import KeycloakService from '../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../utils/roles'
import { selectLocations } from '../../location/locationSlice'
import { useAppSelector } from '../../../app/hooks'
import { PractitionerOption } from './UserForm'
import KeycloakRolesDialog from './KeyclockRoles'
import { useNavigate } from 'react-router-dom'
import { activeText, inactiveText } from '../../Radiology/constants'
import { PageSize } from '../../appointments/models'
import { USER_CREATATION } from '../../bedManagement/endpoints/EndPoints'

const styles = () => ({
  proRithmDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2%',
    gap: 20,
  },
  proRithmDasboardTableContainer: {
    width: '100%',
    height: '100%',
  },
  proRithmDasboardheading: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  proRithmDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: '#4682b4',
  },
  deviceText: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  centerHeader: {
    color: '#ffffff',
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
})

const NewUserDataTable = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [showOperatingUnit, setShowOperatingUnit] = useState(false)
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [odooUser, setOdooUser] = useState<any[]>([])
  const [fhirUser, setFhirUser] = useState<any[]>([])
  const [patientIds, setPatientIds] = useState<string[]>([])
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [keycloakRoles, setKeycloakRoles] = useState<PractitionerOption[]>([])
  const [rolesopen, setRolesOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [operatingUnit, setOperatingUnit] = useState('')
  const locations = useAppSelector(selectLocations)
  const [operatingUnitId] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [editUserData, setEditUserData] = useState<{
    email: string
    firstName: string
    lastName: string
  }>({
    email: '',
    firstName: '',
    lastName: '',
  })

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedUser(null)
  }
  const handleCloseDialog = () => {
    setRolesOpen(false)
  }
  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      alert(UserManagementMessages?.PasswordIncorrect)
      return
    }
    const odooUsera = odooUser?.find(
      (user: any) =>
        user?.name?.toLowerCase() === selectedUser?.username?.toLowerCase()
    )
    handleGetAssginedLocation(selectedUserId)
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.passwordReset, {
      userId: selectedUser?.id,
      value: newPassword,
      odooId: odooUsera?.id,
      name: odooUsera?.name,
      SelectedLocation: operatingUnitId,
    })
      ?.then((data) => {
        if (data?.status === 200) {
          toast?.success(UserManagementMessages?.Done)
        }
      })
      .catch((err) => console.error(UserManagementMessages?.ResetErrorMsg, err))

    setNewPassword('')
    setConfirmPassword('')
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const handleChangeStatus = (
    userId: string,
    username: string,
    active: boolean
  ) => {
    // handleGetAssginedLocation(userId)
    const odooUsera = odooUser?.find(
      (user: any) => user?.name?.toLowerCase() === username?.toLowerCase()
    )
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.inactiveUser, {
      userId,
      username,
      active,
      name: odooUsera?.name,
      odooId: odooUsera?.id,
      SelectedLocation: operatingUnitId,
    })
      ?.then(() => {
        fetchUsers()
      })
      ?.catch((err) => console.error(UserManagementMessages?.EditErrorMsg, err))
  }
  const handleGetAssginedLocation = (keyclockId: any) => {
    setSelectedUserId(keyclockId)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      UserManagementIntent?.UserAssignedLocation,
      { keyclockId }
    )
      ?.then((response) => {
        const operatingUnitId = response?.data?.data?.attributes?.location?.[0]
        if (operatingUnitId) {
          const matchingLocation = locations?.find(
            (location) => location?.resource?.id === operatingUnitId
          )
          if (matchingLocation && matchingLocation?.resource.name) {
            setOperatingUnit(matchingLocation?.resource.name)
          } else {
            setOperatingUnit(UserManagementMessages?.NoMatchLoc)
          }
        } else {
          setOperatingUnit(UserManagementMessages?.Amalapuram)
        }
        setShowOperatingUnit(true)
      })
      .catch((err) =>
        console.error(UserManagementMessages?.KeylockRoleErrorMsg, err)
      )
  }
  const renderStatusSelect = (user: UserData) => {
    return (
      <select
        className='medication-input w75'
        value={user.enabled ? activeText : inactiveText}
        onChange={(e) =>
          handleChangeStatus(
            user?.id,
            user?.username,
            e.target.value === activeText
          )
        }
      >
        <option value={activeText}>{forms?.active}</option>
        <option value={inactiveText}>{forms?.inactive}</option>
      </select>
    )
  }
  const RenderActions = (params: any) => {
    const user = params.data

    return <div>{renderStatusSelect(user)}</div>
  }

  const isCheckboxChecked = (userId: any) => {
    fhirUser?.forEach((id) => {
      const phoneTelecoms = id?.resource?.telecom?.filter(
        (d: any) => d?.system === CreatePartnerForm?.phone
      )
      console.log(CreatePartnerForm?.PhoneTelecom, phoneTelecoms?.[0]?.value)
    })

    return fhirUser?.some(
      (id) => id?.resource?.identifier?.[1]?.value === userId
    )
  }

  const handleKeycloakRoleClick = (userId: any) => {
    setSelectedUserId(userId)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      UserManagementIntent?.keyclockCompositeRoles,
      { userId }
    )
      ?.then((response) => {
        if (response?.data) {
          setKeycloakRoles(
            Array?.isArray(response?.data?.data) ? response?.data?.data : []
          )
          setRolesOpen(true)
        }
      })
      .catch((err) =>
        console.error(UserManagementMessages?.KeylockRoleErrorMsg, err)
      )
  }

  function convertTimestamp(timestamp: any) {
    const date = new Date(timestamp)
    return date?.toLocaleString()
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      valueGetter: (params: any) => {
        return params.node ? params.node.rowIndex + 1 : null
      },
      width: 80,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'UserName',
      field: 'username',
      width: 120,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },

      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Keyclock Id',
      field: 'id',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Name',
      valueGetter: (params) =>
        `${params.data.firstName || ''} ${params.data.lastName || ''}`,
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Email.Id	',
      field: 'email',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Phone No',
      field: 'phoneNumber',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },

    {
      headerName: 'Operating Unit',
      field: 'Operating Unit',
      width: 150,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => {
        return (
          <a
            className='view-visit-button'
            onClick={() => handleGetAssginedLocation(params.data?.id)}
          >
            View
          </a>
        )
      },
    },
    {
      headerName: 'Roles',
      field: 'Roles',
      width: 80,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => (
        <a
          className='view-visit-button'
          onClick={() => handleKeycloakRoleClick(params.data?.id)}
        >
          View
        </a>
      ),
    },
    {
      headerName: 'Edit',
      field: 'edit',
      width: 80,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => {
        return (
          <a
            className='view-visit-button'
            onClick={() => handleEdit(params.data)}
          >
            Edit
          </a>
        )
      },
    },
    {
      headerName: 'Reset Pwd',
      field: 'reset',
      width: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
      cellRenderer: (params: any) => {
        return (
          <button
            className='view-visit-button'
            onClick={() => {
              setSelectedUser(params.data)
              setIsModalOpen(true)
            }}
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            Reset
          </button>
        )
      },
    },

    {
      headerName: 'Created',
      field: 'createdOn',
      width: 150,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        const convertedTimestamp = convertTimestamp(
          params.data?.createdTimestamp
        )
        return <span className='UserDate'>{convertedTimestamp}</span>
      },
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Updated',
      field: 'updatedOn',
      width: 150,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        const fhirId = params?.data?.fhirId

        const createdOn = provenances?.[fhirId]?.createdOn
        const updatedOn = provenances?.[fhirId]?.updatedOn
        const createdBy = provenances?.[fhirId]?.createdBy
        const updatedBy = provenances?.[fhirId]?.updatedBy
        return (
          <div>
            <ProvenanceView
              date={updatedOn ?? createdOn}
              name={updatedBy ?? createdBy}
              mode={'UPDATE'}
            />
          </div>
        )
      },
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      } as INumberFilterParams,
    },
    {
      headerName: 'Thop',
      field: 'thopStatus',
      width: 150,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        const isChecked = params.value == true
        console.log(isChecked, 'sdsdf')
        return (
          <input
            type='checkbox'
            checked={isChecked}
            onChange={() => isCheckboxChecked(params)}
          />
        )
      },
      headerClass: 'custom-header-cell',
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      },
    },

    {
      headerName: 'Odoo',
      field: 'odooStatus',
      width: 150,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        const isChecked = params.value == true
        console.log(isChecked, 'sdsdf')
        return (
          <input
            type='checkbox'
            checked={isChecked}
            onChange={() => isCheckboxChecked(params)}
          />
        )
      },
      headerClass: 'custom-header-cell',
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Status',
      field: 'actions',
      width: 100,
      sortable: false,
      filter: false,
      headerClass: 'custom-header-cell',
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      cellRenderer: RenderActions,
    },
  ]
  const handleSubmitEditedData = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.updatedataKeyclock, {
      email: editUserData?.email,
      firstName: editUserData?.firstName,
      lastName: editUserData?.lastName,
      userid: selectedUserId,
    })
      ?.then((response) => {
        if (response?.data) {
          setKeycloakRoles(
            Array?.isArray(response?.data?.data) ? response?.data?.data : []
          )
          fetchUsers()
          setEditDialogOpen(false)
          toast.success(UserManagementMessages?.UserCreatedMsg)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(UserManagementMessages?.UserErrorMsg)
      })
  }

  const handleEdit = (user: UserData) => {
    setEditDialogOpen(true)
    setEditUserData({
      email: user?.email || 'N/A',
      firstName: user?.firstName || 'N/A',
      lastName: user?.lastName || 'N/A',
    })
    setSelectedUserId(user?.id)
  }
  const navigate = useNavigate()

  const handleCreateUser = () => {
    navigate(USER_CREATATION)
  }
  const FilterActions = () => {
    return (
      <Button
        variant='contained'
        size='small'
        className={classes?.proRithmDasboardCreateBtn}
        onClick={() => handleCreateUser()}
      >
        Create
      </Button>
    )
  }

  useEffect(() => {
    fetchUsers()
    getOdooUsers()
    getFhirUsers()
  }, [])

  const fetchUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.KeyclockUsers, {})
      ?.then((response) => {
        const data = response?.data?.data
        setUsersData(data)
      })
      .catch((err) => console.error(UserManagementMessages?.Fetchusers, err))
  }

  const getOdooUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.getOdooUsers, {})
      ?.then((response) => {
        const data = response?.data
        setOdooUser(data)
      })
      .catch((err) =>
        console.error(UserManagementMessages?.FetchOdooUsers, err)
      )
  }

  const getFhirUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.getfhirUsers)
      ?.then((response) => {
        const data = response?.data?.entry
        setFhirUser(data)
      })
      .catch((err) =>
        console.error(UserManagementMessages?.FetchFhirUsers, err)
      )
  }

  const fetchAndSetProvenances = async (patientIds: string[]) => {
    if (!patientIds?.length) return // Early exit if no patient IDs are present.

    try {
      const patientIdsString = patientIds.join(',')

      const response = await startSxpProxy(
        REGISTRATION_PROJECT_ID,
        UserManagementIntent?.fetchProvenance,
        { patientIds: patientIdsString }
      )

      const entries =
        response?.data?.entry?.map(
          ({ resource }: { resource: Provenance | Practitioner }) => resource
        ) ?? []

      const provenanceData = getProvenanceData(entries)
      setProvenances(provenanceData)
    } catch (error) {
      console.error(UserManagementMessages?.ProvenanceDataErrorMsg, error)
      toast.error(UserManagementMessages?.ProvenanceDataErrorMsg) // Show user-friendly error.
    }
  }

  useEffect(() => {
    fetchAndSetProvenances(patientIds)
  }, [patientIds])

  const matchedUsers = useMemo(() => {
    const matchedUsersList = usersData?.filter((user) =>
      fhirUser?.some(
        (fUser) => fUser?.resource?.identifier?.[1]?.value === user?.id
      )
    )
    const matchedPatientIds = fhirUser
      ?.filter((fUser) =>
        matchedUsersList?.some(
          (user) => fUser?.resource?.identifier?.[1]?.value === user?.id
        )
      )
      ?.map((fUser) => fUser?.resource?.id)
      ?.filter((id) => id !== undefined)

    setPatientIds(matchedPatientIds)
    return matchedUsersList
  }, [usersData, fhirUser])

  const combinedUsersData = useMemo(() => {
    if (!usersData || !fhirUser || !odooUser) {
      return []
    }
    return (
      matchedUsers?.map((user) => {
        const fhirUserData = fhirUser?.find(
          (fUser) => fUser?.resource?.identifier?.[1]?.value === user?.id
        )
        const odooUserData = odooUser?.find(
          (oUser) => oUser?.oauth_uid === user?.id
        )

        const phoneNumber = fhirUserData?.resource?.telecom
          ?.filter((d: any) => d?.system === CreatePartnerForm?.phone)
          ?.map((phone: any) => phone?.value)[0]
        const thopStatus = fhirUser?.some(
          (fUser) => fUser?.resource?.identifier?.[1]?.value === user?.id
        )
        const OdooStatus = odooUser?.some((ou) => ou?.oauth_uid === user?.id)
        const fhirId = fhirUserData?.resource?.id || 'N/A'
        const createdOn = provenances?.[fhirId]?.createdOn || 'N/A'
        const updatedOn = provenances?.[fhirId]?.updatedOn || 'N/A'
        const createdBy = provenances?.[fhirId]?.createdBy || 'N/A'
        const updatedBy = provenances?.[fhirId]?.updatedBy || 'N/A'
        console.log('Merging user data:', { user, fhirUserData, updatedOn })

        return {
          ...user,
          phoneNumber: phoneNumber || 'N/A',
          firstName: fhirUserData?.resource?.name?.[0]?.given?.[0] || 'N/A',
          lastName: fhirUserData?.resource?.name?.[0]?.family || 'N/A',
          fhirId: fhirUserData?.resource?.id || 'N/A',
          odooRole: odooUserData?.role || 'N/A',
          odooStatus: OdooStatus || 'N/A',
          thopStatus: thopStatus || 'N/A',
          createdOn,
          updatedOn,
          createdBy,
          updatedBy,
        }
      }) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fhirUser, odooUser, usersData])

  return (
    <Box height={'93%'} width={'100%'}>
      <Box
        className={classes?.proRithmDasboardContainer}
        height={'100%'}
        width={'100%'}
      >
        <Box
          className={classes?.proRithmDasboardTableContainer}
          height={'100%'}
          width={'100%'}
        >
          <MedAgDataGrid
            tableHeading='Users'
            tableHeight={'100%'}
            rowData={combinedUsersData}
            columnDefs={columnDefs}
            pagination={false}
            pageSizeOptions={PageSize}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            // totalRows={totalCount}
            headerHeight={30}
            filterActions={<FilterActions />}
            searchFieldWidth={200}
          />
        </Box>
        <Dialog
          open={showOperatingUnit}
          onClose={() => setShowOperatingUnit(false)}
        >
          <DialogTitle>{UserManagementMessages?.OperatingUnit}</DialogTitle>
          <DialogContent>{operatingUnit}</DialogContent>
        </Dialog>

        <Dialog open={isModalOpen} onClose={handleModalClose}>
          <DialogTitle>{UserManagementMessages?.ResetPswd}</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handlePasswordReset()
              }}
            >
              <TextField
                label='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <div className='editSubmitCancelBtn'>
                <button type='submit' className='userform-submit-btn'>
                  Submit
                </button>
                <button
                  className='userform-submit-btn'
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <TextField
                label='Email'
                type='text'
                value={editUserData?.email}
                onChange={(e) =>
                  setEditUserData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                fullWidth
                margin='normal'
              />
              <TextField
                label='First Name'
                type='text'
                value={editUserData?.firstName}
                onChange={(e) =>
                  setEditUserData((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
                fullWidth
                margin='normal'
              />
              <TextField
                label='Last Name'
                type='text'
                value={editUserData?.lastName}
                onChange={(e) =>
                  setEditUserData((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
                fullWidth
                margin='normal'
              />
              <div className='editSubmitCancelBtn'>
                <button
                  type='submit'
                  className='userform-submit-btn '
                  onClick={() => handleSubmitEditedData()}
                >
                  Submit
                </button>
                <button
                  className='userform-submit-btn'
                  onClick={() => setEditDialogOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <KeycloakRolesDialog
          isOpen={rolesopen}
          onClose={handleCloseDialog}
          roles={keycloakRoles}
          userId={selectedUserId}
        />
      </Box>
    </Box>
  )
}

export default NewUserDataTable
