import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { ipPlaceHolder, ipVisitApi } from '../models'
import '../bms.scss'
import { useAppSelector, useAppDispatch } from '../../../app/hooks'
import {
  selectSlotsDoctors,
  fetchSlotsDoctorsAsync,
} from '../../slots/slotsSlice'
import { selectLocations } from '../../location/locationSlice'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { mappedPatients } from '../../lms/utils'
import { allocateApi } from '../modals'
import { ipdPatients } from '../constants'
import { toast } from 'react-toastify'
import { fetchAll } from './appointmentsEncounterSlice'
import moment from 'moment'
import { Autocomplete, ListItemText, TextField, useTheme } from '@mui/material'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'

const styles = () => ({
  createVisitAutoComplete: {
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      borderRadius: 'unset',
      padding: '2px',
    },
    '& .MuiInputBase-input': {
      fontSize: '12px',
      borderRadius: 'unset',
    },
  },
})

const IpdVisitCreateForm = ({
  patientBed,
  patientExist,
  onClose,
  patientUhid,
}: {
  patientBed?: any
  patientExist: string
  onClose?: any
  patientUhid: string
}) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [bedPatientList, setBedpatientList] = useState<ipdPatients[]>([])
  const initialState = {
    patientUhid: '',
    patientName: '',
    patientAge: '',
    patientMobile: '',
    bedNo: '',
    location: '',
    admissionDate: '',
    doctor: '',
    department: '',
    bedPatientId: 0,
    bedId: 0,
    patientId: '',
    otherDoctor: '',
    dateAndTime: new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16),
  }

  useEffect(() => {
    if (patientBed) {
      setFormData({
        ...patientBed,
        dateAndTime: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16),
      })
    }
  }, [patientBed])
  useEffect(() => {
    if (patientUhid) {
      setFormData((prev) => ({
        ...prev,
        patientUhid: patientUhid,
        dateAndTime: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16),
      }))
    }
  }, [patientUhid])
  useEffect(() => {
    if (patientExist === 'noExist') {
      executePatient()
    }
  }, [patientExist])
  const executePatient = () => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getBedPatientAssignment, {})
      .then(async (data) => {
        const nodes = data.data?.bed_patient_assignment_map ?? []
        if (nodes.length) {
          const mapped = await mappedPatients(nodes)
          if (mapped) {
            const filtered = mapped.filter(
              (e: any) => e.bedStatus !== 'DISCHARGED'
            )
            setBedpatientList(filtered)
          }
        }
      })
      .catch((err) => console.error(err, 'err'))
  }

  const [formData, setFormData] = useState(initialState)
  useEffect(() => {
    if (formData.patientUhid) {
      formExecution()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.patientUhid, bedPatientList])
  const formExecution = async () => {
    if (formData.patientUhid) {
      const filteredPatientId = bedPatientList.filter(
        (e) => e.patientId === formData.patientUhid
      )
      if (filteredPatientId?.length > 0) {
        const mappedId = filteredPatientId?.[0]
        setFormData((prev) => ({
          ...prev,
          patientName: mappedId?.patientName,
          patientAge: mappedId?.patientAge,
          patientMobile: mappedId?.patientMobile,
          bedNo: mappedId?.bedNumber,
          location: mappedId?.location,
          admissionDate: moment(mappedId?.dateCreated)?.format(
            'Do MMM YYYY, h:mm A'
          ),
          patientId: mappedId?.patient_registered_id,
          bedId: mappedId?.bedId,
          bedPatientId: mappedId?.id,
          dateAndTime: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          patientName: '',
          patientAge: '',
          patientMobile: '',
          bedNo: '',
          location: '',
          admissionDate: '',
          doctor: '',
          department: '',
          bedPatientId: 0,
          bedId: 0,
          patientId: '',
          dateAndTime: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 16),
        }))
      }
    }
    if (formData?.patientUhid === '') {
      setFormData((prev) => ({
        ...prev,
        patientName: '',
        patientAge: '',
        patientMobile: '',
        bedNo: '',
        location: '',
        admissionDate: '',
        doctor: '',
        department: '',
        bedPatientId: 0,
        bedId: 0,
        patientId: '',
        dateAndTime: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, 16),
      }))
    }
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target

    if (name === ipPlaceHolder?.doctorName) {
      const selectedDoctor = doctors?.find((doctor: any) => doctor.id === value)
      setFormData({
        ...formData,
        [name]: value,
        department: selectedDoctor ? selectedDoctor?.specialty : '',
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const doctors = useAppSelector(selectSlotsDoctors)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  const locations = useAppSelector(selectLocations)

  const locationfilter = locations?.find(
    (e) => e?.resource?.name === formData?.location
  )

  const OtherDoctorId =
    doctors?.find((obj) => obj.name === 'Consultant Doctor')?.id || null

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const state = {
      patientId: formData?.patientId,
      doctorId: formData?.doctor,
      locationId: locationfilter?.resource?.id ?? '',
      bedId: `${formData?.bedPatientId}`,
      bedNo: formData?.bedNo,
      admittedDateAndTime: formData?.admissionDate,
      otherDoctorName: formData?.otherDoctor ?? '',
      startTime: moment(formData.dateAndTime)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    }

    if (formData.doctor) {
      startSxpProxy(IPD_PROJECT_ID, ipVisitApi.createSubEncounter, state)
        .then(async () => {
          await dispatch(fetchAll({ id: formData?.patientId }))
          onClose()
          toast.success('Visit created sucessfully.')
        })
        .catch((error) => {
          console.error(error, 'error')
        })
    }
  }

  return (
    <>
      <div className='input-content input-border'>
        <div className='input-context'>
          <span className='span-input'>Create Visit</span>
        </div>
        <form className='form-grid' onSubmit={handleSubmit}>
          <div className='visit-input'>
            <label htmlFor='patientId'>
              UHID :
              <Asterisk />
            </label>
            <input
              required
              name='patientUhid'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.patientUhid}
              value={formData?.patientUhid ?? ''}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='patientName'>
              Name:
              <Asterisk />
            </label>
            <input
              required
              name='patientName'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.patientName}
              value={formData?.patientName}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='patientMobile'>
              Mobile No.:
              <Asterisk />
            </label>
            <input
              required
              name='patientMobile'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.patientMobile}
              value={formData?.patientMobile}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='PatientAge'>
              Age:
              <Asterisk />
            </label>
            <input
              required
              name='patientAge'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.patientAge}
              value={formData?.patientAge}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='location'>
              Operating Unit
              <Asterisk />
            </label>
            <input
              required
              name='location'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.location}
              value={formData?.location}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='BedNo'>
              Bed No:
              <Asterisk />
            </label>
            <input
              required
              name='bedNo'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.bedNo}
              value={formData?.bedNo}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='admittedDate'>
              Admitted Date:
              <Asterisk />
            </label>
            <input
              required
              name='admissionDate'
              type='text'
              className='input-visit'
              placeholder={ipPlaceHolder.admissionDate}
              value={formData?.admissionDate}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='NameOfDoctor'>
              Name Of Doctor:
              <Asterisk />
            </label>
            <Autocomplete
              disableClearable
              className='input-visit-autoComplete'
              value={doctors.find((doctor) => doctor?.id === formData?.doctor)}
              options={doctors}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value) =>
                handleChange({
                  target: { name: 'doctor', value: value ? value.id : '' },
                } as ChangeEvent<HTMLInputElement>)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  placeholder='Doctors Name'
                  className={classes?.createVisitAutoComplete}
                />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderOption={(props, item) => (
                <li {...props} key={item?.id}>
                  <ListItemText>{item?.name}</ListItemText>
                </li>
              )}
            />
          </div>
          {formData?.doctor == OtherDoctorId ? (
            <div className='visit-input'>
              <label htmlFor='OtherDoctor'>
                Name of Other Doctor:
                <Asterisk />
              </label>
              <input
                required
                className='input-visit'
                name='otherDoctor'
                type='text'
                placeholder={ipPlaceHolder.otherDoctor}
                value={formData?.otherDoctor}
                onChange={handleChange}
              />
            </div>
          ) : null}
          <div className='visit-input'>
            <label htmlFor='Department'>
              Department:
              <Asterisk />
            </label>
            <input
              required
              className='input-visit'
              name='department'
              type='text'
              placeholder={ipPlaceHolder.department}
              value={formData?.department}
              onChange={handleChange}
            />
          </div>
          <div className='visit-input'>
            <label htmlFor='Department'>
              Date&Time:
              <Asterisk />
            </label>
            <input
              // min={getCurrentDateTime()}
              required
              className='input-visit'
              name='dateAndTime'
              type='datetime-local'
              placeholder='date and Time'
              value={formData?.dateAndTime}
              onChange={handleChange}
            />
          </div>
          <button
            id='create-visit-btn'
            className='createvisit-submitbtn '
            type='submit'
          >
            Create
          </button>
        </form>
      </div>
    </>
  )
}
export default IpdVisitCreateForm
