import { toast } from 'react-toastify'
import { startSxpProxy } from '../../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  IPD_PROJECT_ID,
  LABS_PROJECT_ID,
} from '../../../../utils/constants'
import KeycloakService from '../../../../utils/keycloakService'
import { activeText, inactiveText } from '../../../Radiology/constants'
import { AdminIntent, menuValues } from '../../constants'
import { messages } from '../../../administration/constants'
import { toastOptions } from '../../models'
import { intent as AdminMainIntent } from '../../../../features/administration/constants'

export function Archive(data: any, setData: any, item: any) {
  let intent: string
  let state = {}
  let projectId: any
  let specialty: any

  if (data?.id && data?.speciality) {
    specialty = data?.speciality?.split?.('::') ?? []
  }

  if (item && item !== menuValues?.Sequence) {
    switch (item) {
      case menuValues?.Sample:
        ;(intent = AdminIntent?.ArchiveSample),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          }),
          (projectId = LABS_PROJECT_ID)
        break
      case menuValues?.Panel:
        ;(intent = AdminIntent?.InactiveTests),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            isStatus: data?.status === activeText ? inactiveText : activeText,
            odooPackageId: data?.odoo_test_id,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Parameter:
        ;(intent = AdminIntent?.ArchiveLabTest),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.LabDepartment:
        ;(intent = AdminIntent?.ArchiveLabDepartment),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          }),
          (projectId = LABS_PROJECT_ID)
        break
      case menuValues?.UnitType:
        ;(intent = AdminIntent?.ArchiveUnitType),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.ResultType:
        ;(intent = AdminIntent?.ArchiveResultType),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Method:
        ;(intent = AdminIntent?.ArchiveMethodType),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.ParameterRange:
        ;(intent = AdminIntent?.ArchiveTestRange),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Packages:
        ;(intent = AdminIntent?.InactivePackage),
          (projectId = LABS_PROJECT_ID),
          (state = {
            id: data?.id,
            isStatus: data?.status === activeText ? inactiveText : activeText,
            odooPackageId: data?.package_id,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Ward:
        ;(intent = AdminIntent?.ArchiveWard),
          (projectId = IPD_PROJECT_ID),
          (state = {
            id: data?.id,
            active: !data?.active,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Room:
        ;(intent = AdminIntent?.ArciveRoom),
          (projectId = IPD_PROJECT_ID),
          (state = {
            id: data?.id,
            active: !data?.active,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Bed:
        ;(intent = AdminIntent?.ArchiveBed),
          (projectId = IPD_PROJECT_ID),
          (state = {
            id: data?.id,
            active: !data?.active,
            updatedBy: KeycloakService.getUsername(),
            updatedDate: new Date().toISOString(),
          })
        break
      case menuValues?.Doctor:
        ;(intent = AdminMainIntent?.doctorActive),
          (projectId = ADMIN_PROJECT_ID),
          (state = {
            id: data?.id,
            lastName: data?.lastName,
            firstName: data?.firstName,
            middleName: '',
            salutation: data?.salutation,
            email: data?.email,
            mobileNumber: data?.phone,
            regNumber: data?.regNumber,
            qualificationValue: data?.qualification,
            gender: data?.gender,
            dob: data?.dob,
            specialtyId: specialty?.[1],
            specialtyName: specialty?.[0],
            healthCareServiceId: data?.healthService?.join(','),
            active: !data?.active,
            operatingUnit: data?.operatingUnit ?? '-',
          })
        break
      case menuValues?.Location:
        if (data?.resource?.id) {
          ;(intent = AdminMainIntent?.editLocation),
            (projectId = ADMIN_PROJECT_ID),
            (state = {
              id: data?.resource.id,
              locationName: data?.resource.name,
              locationPrefix: data?.resource.identifier?.[1]?.value,
              address: data?.resource?.address?.line?.[0],
              email: data?.resource?.telecom?.[1]?.value,
              mobile: data?.resource?.telecom?.[0]?.value,
              status:
                data?.resource?.status === activeText
                  ? inactiveText
                  : activeText,
              dlNumber: data?.resource?.type?.[0].coding?.[0]?.display,
              hasInventory: data?.resource?.type?.[0].coding?.[3]?.display,
              type: data?.resource?.type?.[0].coding?.[2]?.display,
              gst: data?.resource?.type?.[0].coding?.[1]?.display,
            })
        } else {
          intent = ''
        }
        break
      case menuValues?.Pathologist:
        ;(intent = AdminMainIntent?.labInchargeActive),
          (projectId = ADMIN_PROJECT_ID),
          (state = {
            id: data?.id,
            lastName: data?.lastName,
            firstName: data?.firstName,
            middleName: '',
            salutation: data?.salutation,
            email: data?.email,
            mobileNumber: data?.phone,
            regNumber: data?.regNumber,
            qualificationValue: data?.qualificationValue,
            gender: data?.gender,
            dob: data?.dob,
            specialtyId: specialty?.[1],
            specialtyName: specialty?.[0],
            active: !data?.active,
          })
        break
      case menuValues?.Partner:
        ;(intent = AdminMainIntent?.partnerArchive),
          (projectId = ADMIN_PROJECT_ID),
          (state = {
            partner_id: data?.id,
            odooId: data?.odoo_partner_id,
            status: data?.status === activeText ? inactiveText : activeText,
            updatedBy: KeycloakService?.getUsername(),
            updatedDate: new Date()?.toISOString(),
          })
        break
      default:
        intent = ''
    }

    if (intent) {
      startSxpProxy(projectId, intent, state)
        ?.then(() => {
          if (data?.id) {
            setData((prevData: any) =>
              prevData?.filter((prev: any) => prev?.id !== data?.id)
            )
          } else {
            setData((prevData: any) =>
              prevData?.filter(
                (prev: any) => prev?.resource?.id !== data?.resource?.id
              )
            )
          }
          if (
            data?.status === activeText ||
            data?.active ||
            data?.resource?.status === activeText
          ) {
            toast.success(messages?.archiveSuccess, toastOptions)
          } else {
            toast.success(messages?.unarchiveSuccess, toastOptions)
          }
        })
        ?.catch((err) => {
          console.error(messages?.ErrorMessage, err)
        })
    }
  }
}
