import React, { useEffect } from 'react'
import { selectSelectedLocationId } from '../location/locationSlice'
import { useAppSelector } from '../../app/hooks'
import IpdRegistrationForm from './IpdRegistrationForm'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import IpdRegistrationTable from './IpdRegistrationTable'
import {
  clearPatientDetails,
  getIsLoading,
  getPatientDetails,
} from './IpdPatientRegisterSlice'
import { useDispatch } from 'react-redux'
import OverlayLoader from '../../components/OverlayLoader'
import { KIMS_LOCATION_ID } from '../../utils/constants'

const styles = () => ({
  ipdRegisterMain: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
    },
  },
  ipdRegisterHeading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '17px',
    fontWeight: '600',
    color: '#090968',
    padding: '10px 0 0 0px',
  },
  ipdRegisterTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  ipdRegisterFormTableContainer: {
    height: '77vh',
    overflowY: 'auto',
  },
})

const IpdRegistration = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useCustomStyles(styles, theme)
  const locationId = useAppSelector(selectSelectedLocationId)
  const loading = useAppSelector(getIsLoading)
  const patientDetails = useAppSelector(getPatientDetails)

  const [registrationType, setRegistrationType] = React.useState('existing')

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await dispatch(clearPatientDetails())
    setRegistrationType((event.target as HTMLInputElement).value)
  }

  const renderForm = (registrationType: string) => {
    if (registrationType === 'existing') {
      return patientDetails?.muhId && <IpdRegistrationForm />
    } else {
      return <IpdRegistrationForm />
    }
  }

  useEffect(() => {
    if (locationId !== KIMS_LOCATION_ID) {
      setRegistrationType('existing')
    }
  }, [locationId])

  return (
    <OverlayLoader isActive={loading?.active} message={loading?.message}>
      <div>
        <div className={classes?.ipdRegisterMain}>
          {locationId === KIMS_LOCATION_ID && (
            <div className={classes?.ipdRegisterTypeContainer}>
              <FormControl>
                <RadioGroup
                  row
                  name='registrationType'
                  value={registrationType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value='existing'
                    control={<Radio size='small' />}
                    label='Existing'
                  />

                  <FormControlLabel
                    value='new'
                    control={<Radio size='small' />}
                    label='New'
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className={classes?.ipdRegisterFormTableContainer}>
            {registrationType === 'existing' ? <IpdRegistrationTable /> : null}
            {renderForm(registrationType)}
          </div>
        </div>
      </div>
    </OverlayLoader>
  )
}

export default IpdRegistration
