import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { uploadImage } from '../../../utils/api'
import { Patient } from 'fhir/r4'
import { FILE_SERVER_URL } from '../../../utils/constants'
import { makeName } from '../utils'
import { TESTING_WRITE, VALIDATION_WRITE } from '../../../utils/roles'
import { fullDateTimeFormat } from '../../../utils/dateUtils'
import { emptyString } from '../../Radiology/constants'
import {
  LabResult,
  LmsValues,
  Organization,
  activeTabs,
  labDepartments,
  selectPathologist,
  testStatus,
} from '../models'

import KeycloakService from '../../../utils/keycloakService'
import TestRow from './TestRow'
import uploadIcon from '../../../assets/icons/UploadBlue.svg'

import { useAppSelector } from '../../../app/hooks'
import { selectedLmsActiveTab } from '../lmsSlice'
import { referredTo, validatedOn } from '../constants'
import {
  entered,
  rejected,
  testing,
  validation,
} from '../../administration/constants'

type Props = {
  activeId: number
  tests: LabResult[]
  patient: Patient | null
  onReferredOutChange?: (referred: boolean, panelId: number) => void
  onReferredToChange?: (referred: number, panelId: number) => void
  onDocUrlChange: (
    referred: string,
    panelId: number,
    patientId?: string
  ) => void
  onValueChange: (val: string, index: number) => void
  onNotesChange: (val: string, index: number) => void
  onExtraValueChange: (val: string, index: number) => void
  onValuesChange: (values: LmsValues, index: number) => void
  onEnter: (id: number) => void
  isReferred: boolean
  disabled: boolean
  organizations: Organization[]
  onSubmit?: (results: LabResult[]) => void
  onApprove?: (
    results: LabResult[],
    reason: string,
    pathologist?: string
  ) => void
  onReject?: (results: LabResult[], reason: string) => void
  orderStatus?: string
  valuesUpdated?: boolean
  onEditCancel?: (edit: boolean) => void
  apiLoading: boolean
  labDepartments?: labDepartments[]
  onCheckboxChange?: (
    testId: number,
    isChecked: boolean,
    tests: LabResult[]
  ) => void
  onCheckboxApprove?: (
    testId: number,
    isCheckedApprove: boolean,
    tests: LabResult[],
    pathologist: string,
    reason: string
  ) => void
  allChecked?: boolean
  allApproveCheck?: boolean
  onPathologists?: (pathologist: string, testId: number) => void
  onReasons?: (reason: string, testId: number) => void
  mode: string
  testingCount?: number
  validateCount?: number
}

const TestGroup = ({
  activeId,
  tests,
  patient,
  onReferredOutChange,
  onReferredToChange,
  onDocUrlChange,
  onValueChange,
  onNotesChange,
  onExtraValueChange,
  onValuesChange,
  onEnter,
  isReferred,
  disabled,
  organizations,
  // onSubmit,
  // onApprove,
  // onReject,
  valuesUpdated,
  onEditCancel,
  // apiLoading,
  labDepartments,
  onCheckboxChange,
  onCheckboxApprove,
  allChecked,
  allApproveCheck,
  onPathologists,
  onReasons,
  mode,
  testingCount,
  validateCount,
}: Props) => {
  const docsRef = useRef<HTMLInputElement | null>(null)
  const test = tests?.find((t) => t?.test_status !== rejected) ?? tests?.[0]
  const [reason, setReason] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [lab, setLab] = useState<labDepartments[]>([])
  const [pathologist, setPathologist] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isCheckApprove, setIsCheckApprove] = useState(false)

  useEffect(() => {
    if (labDepartments && labDepartments?.length > 0) {
      setLab(labDepartments)
    }
  }, [labDepartments])
  const handleFileButton = () => {
    docsRef.current?.click()
  }

  const handleEdit = () => {
    setIsEdit(!isEdit)
    onEditCancel?.(isEdit)
  }

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0]
    if (file) {
      const formData = new FormData()
      formData?.append('file', file, 'report.pdf')
      uploadImage(formData)
        .then((data) => {
          const uploaded = data?.data?.[0]
          onDocUrlChange(
            `${FILE_SERVER_URL}file/${uploaded}`,
            test?.lab_test?.panel?.id,
            patient?.id
          )
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      onDocUrlChange('', test?.lab_test?.panel?.id, patient?.id)
    }
  }
  const isCheckTrue =
    !valuesUpdated &&
    !isReferred &&
    KeycloakService?.hasRole([TESTING_WRITE]) &&
    tests?.every(
      (t) =>
        t?.test_status === rejected ||
        (t?.test_status === entered && (t?.value || t?.values?.summary))
    )
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked
    setIsChecked(newChecked)
    onCheckboxChange?.(test?.lab_test?.panel?.id, newChecked, tests)
  }
  const handleCheckboxApproveChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newChecked = event.target.checked
    setIsCheckApprove(newChecked)
    onCheckboxApprove?.(
      test?.lab_test?.panel?.id,
      newChecked,
      tests,
      pathologist,
      reason
    )
  }
  useEffect(() => {
    if (allChecked) {
      if (
        tests?.every(
          (t) =>
            t?.test_status === rejected ||
            (t?.test_status === entered && (t?.value || t?.values?.summary))
        )
      ) {
        setIsChecked(allChecked)
        onCheckboxChange?.(test?.lab_test?.panel?.id, false, tests)
        onCheckboxChange?.(test?.lab_test?.panel.id, true, tests)
      }
    } else {
      setIsChecked(false)
      onCheckboxChange?.(test?.lab_test?.panel?.id, false, tests)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testingCount])
  useEffect(() => {
    if (allApproveCheck) {
      if (
        tests?.every(
          (t) =>
            t?.test_status === rejected ||
            (t?.test_status === testStatus?.submitted &&
              (t?.value || t?.values?.summary))
        )
      ) {
        setIsCheckApprove(allApproveCheck)
        onCheckboxApprove?.(
          test?.lab_test?.panel?.id,
          false,
          tests,
          pathologist,
          reason
        )
        onCheckboxApprove?.(
          test?.lab_test?.panel?.id,
          true,
          tests,
          pathologist,
          reason
        )
      }
    } else {
      setIsCheckApprove(false)
      onCheckboxApprove?.(
        test?.lab_test?.panel?.id,
        false,
        tests,
        pathologist,
        reason
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCount])
  const handlePathName = (event: ChangeEvent<HTMLSelectElement>) => {
    const path = event.target.value
    setPathologist(path)
    onPathologists?.(path, test?.lab_test?.panel?.id)
  }
  const handleReason = (event: any) => {
    const path = event?.target?.value
    setReason(path)
    onReasons?.(path, test?.lab_test?.panel?.id)
  }
  const activeTab = useAppSelector(selectedLmsActiveTab)
  const handleReferChange = () => {
    const newReferredOutState = !test?.referred_out
    onReferredOutChange?.(
      test?.referred_out ?? false,
      test?.lab_test?.panel?.id
    )

    if (newReferredOutState) {
      onDocUrlChange('', test?.lab_test?.panel?.id, patient?.id)
    }
  }
  return (
    <section className='results-section'>
      <div className='test-group w100'>
        <div
          className={`${
            mode !== 'referred' ? 'expand-content' : 'referred-expand-content'
          }`}
        >
          <div className='pointer' onClick={() => setIsExpanded(!isExpanded)}>
            <span className='blueColor'> {isExpanded ? '▲' : '▼'}</span>
          </div>

          <h3
            className='test-name pointer'
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {test?.lab_test?.panel?.name}
            {isReferred && (
              <span>
                &nbsp;[{makeName(patient?.name)} -{' '}
                {patient?.identifier?.[2]?.value ??
                  patient?.identifier?.[0]?.value ??
                  ''}
                ]
              </span>
            )}
          </h3>
        </div>
        {mode !== 'referred' && (
          <div className='status-content'>
            {!isReferred && (
              <span className='text-black'>
                {test?.test_status === testStatus?.entered ||
                test?.test_status === testStatus?.collected
                  ? activeTabs?.testing
                  : test?.test_status === testStatus?.submitted
                  ? activeTabs?.validation
                  : test?.test_status === testStatus?.approved
                  ? activeTabs?.dispatch
                  : null}
              </span>
            )}
          </div>
        )}
        <div
          className={`${
            mode !== 'referred' ? 'check-content' : 'referred-check-content'
          }`}
        >
          <div
            className={`${
              mode !== 'referred'
                ? 'upload-image-container'
                : 'referred-image-container'
            }`}
          >
            <img
              title='Upload Documents'
              src={uploadIcon}
              alt='upload button'
              onClick={handleFileButton}
              className={`${
                mode !== 'referred' ? 'w54' : 'referred-image-content'
              }`}
            />

            <input
              id='docUrl'
              ref={docsRef}
              type='file'
              accept='application/pdf'
              className='display-none'
              onChange={handleFileChange}
              disabled={
                (test?.referred_out === true && mode !== 'referred') ||
                activeTab === 'validation'
              }
            />

            {test?.document_url && (
              <a
                href={test?.document_url}
                target='_blank'
                rel='noreferrer'
                download
              >
                Report
              </a>
            )}
          </div>
          <div>
            {isReferred ? (
              <span className='referred-text'>
                {referredTo}:
                {organizations?.find((o) => o?.id === test?.referred_to)
                  ?.name ?? ''}
              </span>
            ) : (
              <>
                <input
                  type='checkbox'
                  checked={test?.referred_out ?? false}
                  disabled={
                    disabled ||
                    test?.test_status === testStatus?.approved ||
                    test?.test_status === testStatus?.submitted ||
                    activeTab === validation
                  }
                  onChange={handleReferChange}
                />
                <select
                  name='Referred'
                  value={test?.referred_to ?? 0}
                  disabled={
                    disabled ||
                    test?.test_status === testStatus?.approved ||
                    test?.test_status === testStatus?.submitted ||
                    activeTab === validation
                  }
                  onChange={(ev) =>
                    onReferredToChange?.(
                      parseInt(ev.target.value),
                      test?.lab_test?.panel?.id
                    )
                  }
                >
                  <option value={0}>Choose an option</option>
                  {organizations?.map((org) => (
                    <option key={org?.id} value={org?.id}>
                      {org?.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>
        <div
          className={`${
            mode !== 'referred'
              ? `${test?.referred_out ? 'test-flex w25' : 'submitted'}`
              : ''
          }`}
        >
          {isCheckTrue && (
            <input
              type='checkbox'
              onChange={handleCheckboxChange}
              checked={isChecked}
              disabled={activeTab !== testing}
            />
          )}

          {test?.test_status === testStatus?.submitted && (
            <>
              <div className='submitted-container lab-pathology-select'>
                <div>
                  <button
                    disabled={activeTab !== validation}
                    onClick={handleEdit}
                  >
                    {isEdit ? 'Cancel Editing' : 'Edit'}
                  </button>
                </div>
                <div className='approve lab-pathology-approve-selectDiv'>
                  <>
                    <input
                      type='checkbox'
                      onChange={handleCheckboxApproveChange}
                      checked={isCheckApprove}
                      disabled={activeTab !== 'validation'}
                    />
                  </>
                  {!test?.referred_out && (
                    <div className='lab-pathology-select-div'>
                      <select
                        className='pathology-input lab-pathology-select'
                        value={pathologist}
                        onChange={handlePathName}
                        disabled={
                          test?.test_status !== testStatus?.submitted ||
                          activeTab === testing
                        }
                      >
                        <option value={emptyString}>{selectPathologist}</option>
                        {lab?.map((org: any) => (
                          <option key={org?.id} value={org?.id}>
                            {org?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          {tests?.map((t) => (
            <>
              <TestRow
                key={t.id}
                test={t}
                patient={patient}
                activeId={activeId}
                onValueChange={onValueChange}
                onNotesChange={onNotesChange}
                onExtraValueChange={onExtraValueChange}
                onValuesChange={onValuesChange}
                onEnter={onEnter}
                disabled={
                  disabled ||
                  test?.test_status === testStatus?.approved ||
                  test?.test_status === testStatus?.submitted ||
                  (activeTab === 'validation' &&
                    (test?.test_status === testStatus?.collected ||
                      test?.test_status === testStatus?.entered))
                }
                isReferred={isReferred}
                isEdit={isEdit}
              />
            </>
          ))}
          {test?.test_status === 'SUBMITTED' &&
            (!valuesUpdated || isEdit) &&
            !isReferred &&
            KeycloakService.hasRole([VALIDATION_WRITE]) && (
              <div className='test-group-actions'>
                <textarea
                  className='result-summary'
                  name='Comments'
                  placeholder='Comments'
                  value={reason}
                  onChange={handleReason}
                  cols={80}
                  rows={7}
                ></textarea>
              </div>
            )}
          {test?.validated_on && (
            <p className='admin-header'>
              {validatedOn} {fullDateTimeFormat(new Date(test?.validated_on))}{' '}
              {test?.validated_by && <span>[{test?.validated_by}]</span>}
            </p>
          )}
        </>
      )}
    </section>
  )
}

export default TestGroup
